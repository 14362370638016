import { useNavigate } from "react-router-dom";
import { Flex, Typography } from "antd";
import { SoundOutlined } from "@ant-design/icons";
import { WordDefinition } from "../../data/interfaces/dictionary";

const { Title, Text } = Typography;

const SearchResult = ({result}: {result: WordDefinition}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/dictionary/${result.id}`);
  }

  return (
    <Flex vertical className="w-full" style={{ borderBottom: '1px solid #DDE1E6', padding: '1rem', cursor: 'pointer' }} onClick={handleClick}>
      <Title level={5} style={{ marginBottom: 0 }}>
        {`${result.word} ${result.pinyin} `}
        {result.audio && <SoundOutlined style={{ color: '#007AFF', fontSize: '1rem', marginLeft: '.5rem' }} />} 
      </Title>
      <Text>{result.definitions.map((d) => d + ' ')}</Text>
    </Flex>
  )
}

export default SearchResult;