import { Button, Flex, Typography } from "antd";
import { SoundOutlined } from "@ant-design/icons";
import { Word } from "../../data/interfaces/document";

const { Title, Text } = Typography;

const DictionaryPop = ({word}: {word: Word}) => {
  return (
    <Flex className="flex-col" gap={6} style={{ width: '200px' }}>
      <Flex gap={8} align="center">
        <Title level={5} style={{ marginBottom: 0 }}>{word.text}</Title>
        <Button icon={<SoundOutlined />} style={{ border: 'none', boxShadow: 'none', background: 'transparent', width: '1.5rem' }} />
      </Flex>
      <Title level={5} style={{ margin: 0 }}>{word.partOfSpeech}</Title>
      <Title level={5} style={{ margin: 0 }}>{word.pinyin}</Title>
      <Title level={5} style={{ margin: 0 }}>{word.translation}</Title>
      {/* {wordData.definitions.map((def, index) => <Title key={index} level={5} style={{ margin: 0 }}>{def}</Title>)} */}
      <Flex className="flex-col" gap={8} align="start" style={{ borderTop: '1px solid #DDE1E6', paddingTop: '8px' }}>
        <Text>Sentences</Text>
        <Text>Add to flashcards</Text>
      </Flex>
    </Flex>
  )
}

export default DictionaryPop;