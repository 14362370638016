import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Button, Flex, Modal, Typography, Input, Checkbox, Space, notification, message } from 'antd';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { AddNewTextDto, DocumentDto, DocumentInterface, ShowMode } from '../../data/interfaces/document';
import { RootState } from '../../store';
import { editText } from '../../utils/supabase';

const { TextArea } = Input;
const { Text, Title } = Typography;

const DocumentEditModal = ({document, open, setOpen, callback}: {document: DocumentDto | null, open: boolean, setOpen: (value: boolean) => void, callback: () => Promise<void>}) => {
  const user = useSelector((state: RootState) => state.auth.user);
  const [step, setStep] = useState<number>(1);
  const [title, setTitle] = useState<string>('');
  const [text, setText] = useState<string>('');
  const [loading, setLoading] = useState<boolean>(false);
  const [description, setDescription] = useState<string>('');
  const [showMode, setShowMode] = useState<ShowMode>({py: true, en: true, au: true, wp: true, fc: true, ex: true})
  const [api, contextHolder] = notification.useNotification();

  useEffect(() => {
    if (document) {
      setTitle(document.titleMand);
      setText(document.textMand || '');
    }
  }, [document])
  
  const handleCheckboxChange = (e: CheckboxChangeEvent, option: string) => {
    e.stopPropagation();
    setShowMode((prev) => ({
      ...prev,
      [option]: e.target.checked,
    }))
  }

  const handleSubmit = async () => {
    try {
      if (document?.id) {
        const newDocumentJson: DocumentInterface = {
          metadata: {
            title: title,
            source: description,
            createdAt: new Date().toString(),
            isFavorite: false,
            isCompleted: false,
          },
          content: {
            fullText: text,
            paragraphs: []
          }
        }
        const newDocumentDto: AddNewTextDto = {
          titleMand: title,
          textMand: text,
          status: 'new',
          email: user.email,
          is_favorite: false,
          json_structure: JSON.stringify(newDocumentJson)
        }
    
        setLoading(true);
        await editText(newDocumentDto, document?.id);
        setLoading(false);
        setOpen(false);
        callback();
        openNotification();
      }
    } catch (error) {
      message.error((error as Error).message);
      setLoading(false);
    }
  }

  const openNotification = () => {
    api.open({
      message: 'Generation completed',
      description: (
        <Flex vertical gap={8}>
          <Text>Content generation for {title} completed:</Text>
          <Flex align='center'>
            <CheckCircleOutlined style={{ color: '#52C41A', marginRight: 8 }} />
            <Text>Pinyin translation</Text>
          </Flex>
          <Flex align='center'>
            <CheckCircleOutlined style={{ color: '#52C41A', marginRight: 8 }} />
            <Text>English translation</Text>
          </Flex>
          <Flex align='center'>
            <CloseCircleOutlined style={{ color: 'red', marginRight: 8 }} />
            <Text>Audio</Text>
          </Flex>
          <Flex align='center'>
            <CheckCircleOutlined style={{ color: '#52C41A', marginRight: 8 }} />
            <Text>Words & phrases</Text>
          </Flex>
          <Flex align='center'>
            <CheckCircleOutlined style={{ color: '#52C41A', marginRight: 8 }} />
            <Text>Flashcards</Text>
          </Flex>
          <Flex align='center'>
            <CheckCircleOutlined style={{ color: '#52C41A', marginRight: 8 }} />
            <Text>Exercises</Text>
          </Flex>
        </Flex>
      ),
      btn: <Button type='primary'>Ok</Button>,
      key: 'open' + {Date},
    })
  }

  const modalTitle = () => {
    switch (step) {
      case 1:
        return 'Edit text?'
    
      case 2:
        return 'Edit text'
    
      case 3:
        return 'Document settings'
    
      default:
        break;
    }
  }

  const footer = () => {
    switch (step) {
      case 1:
        return (
          <Flex className='w-full'>
            <Button key='cancel' onClick={() => setOpen(false)} style={{ borderRadius: 2 }}>Cancel</Button>
            <Button key='continue' type='primary' style={{ borderRadius: 2, marginLeft: 'auto' }} onClick={() => setStep(2)}>Edit</Button>
          </Flex>
        )

      case 2:
        return (
          <Flex className='w-full'>
            <Button key='cancel' onClick={() => setOpen(false)} style={{ borderRadius: 2 }}>Cancel</Button>
            <Button key='continue' type='primary' style={{ borderRadius: 2, marginLeft: 'auto' }} onClick={() => setStep(3)}>Continue</Button>
          </Flex>
        )

      case 3:
        return [
          <Button key='cancel' onClick={() => setOpen(false)} style={{ borderRadius: 2 }}>Cancel</Button>,
          <Button key='continue' type='primary' loading={loading} onClick={handleSubmit} style={{ borderRadius: 2 }}>Save</Button>
        ]
          
      default:
        break;
    }
  }

  const body = () => {
    switch (step) {
      case 1:
        return (<Flex vertical gap={24} style={{ borderTop: '1px solid #D9D9D9', borderBottom: '1px solid #D9D9D9', padding: '1rem 0' }}>
          <Text>Once you edit the text, new data will be generated based on the updated content, and any previous data will be lost. This action cannot be undone.</Text>
        </Flex>)

      case 2:
        return (<Flex vertical gap={16} style={{ borderTop: '1px solid #D9D9D9', borderBottom: '1px solid #D9D9D9', padding: '1rem 0' }}>
          <Text>Enter chinese text and we will generate translations, flashcards & exercises for you</Text>
          <TextArea rows={16} placeholder='Paste text here' value={text} onChange={(e) => setText(e.target.value)} style={{ borderRadius: 2 }} />
        </Flex>)

      case 3:
        return (<Flex vertical gap={24} style={{ borderTop: '1px solid #D9D9D9', borderBottom: '1px solid #D9D9D9', padding: '1rem 0' }}>
          <Text>You can change auto generated title and description if you need</Text>
          <Flex vertical>
            <Title level={5}><span style={{ color: 'red', marginRight: '.2rem' }}>*</span>Title</Title>
            <Input value={title} onChange={(e) => setTitle(e.target.value)} style={{ borderRadius: 2 }} />
          </Flex>
          <Flex vertical>
            <Title level={5}><span style={{ color: 'red', marginRight: '.2rem' }}>*</span>Description</Title>
            <Input value={description} onChange={(e) => setDescription(e.target.value)} style={{ borderRadius: 2 }} />
          </Flex>
          <Flex vertical>
            <Title level={5}>Generate data field</Title>
            <Text>Using AI, we will generate for you translations, words, phrases, flashcards & exercises. It can take up to 10 minutes.</Text>
            <Checkbox checked={showMode.py} onChange={(e) => handleCheckboxChange(e, 'py')} style={{ borderRadius: 2 }}>Pinyin translation</Checkbox>
            <Checkbox checked={showMode.en} onChange={(e) => handleCheckboxChange(e, 'en')} style={{ borderRadius: 2 }}>English translation</Checkbox>
            <Checkbox checked={showMode.au} onChange={(e) => handleCheckboxChange(e, 'au')} style={{ borderRadius: 2 }}>Audio</Checkbox>
            <Checkbox checked={showMode.wp} onChange={(e) => handleCheckboxChange(e, 'wp')} style={{ borderRadius: 2 }}>Words & phrases</Checkbox>
            <Checkbox checked={showMode.fc} onChange={(e) => handleCheckboxChange(e, 'fc')} style={{ borderRadius: 2 }}>Flashcards</Checkbox>
            <Checkbox checked={showMode.ex} onChange={(e) => handleCheckboxChange(e, 'ex')} style={{ borderRadius: 2 }}>Exercises</Checkbox>
          </Flex>
        </Flex>)
    
      default:
        break;
    }
  }

  return (
    <Space onClick={(e) => e.stopPropagation()}>
      {contextHolder}
      <Modal
        open={open}
        title={modalTitle()}
        onCancel={() => setOpen(false)}
        footer={footer()}
        centered
      >
        {body()}
      </Modal>
    </Space>
  );
};

export default DocumentEditModal;