import { AddNewTextDto } from "../data/interfaces/document";
import { clearAuth, setAuth } from "../store/authSlice";
import { supabase } from "../supabaseClient";

export const signUp = async (email: string, password: string, dispatch: any) => {
  const { data: { session }, error } = await supabase.auth.signUp({ email, password });

  if (error) {
    throw error;
  }

  dispatch(setAuth(session?.user))
}

export const signIn = async (email: string, password: string, dispatch: any) => {
  const { data: { session }, error } = await supabase.auth.signInWithPassword({ email, password });

  if (error) {
    throw error;
  }

  dispatch(setAuth(session?.user));
}

export const resetPassword = async (email: string, redirectTo: string) => {
  const { error } = await supabase.auth.resetPasswordForEmail(email, { redirectTo: redirectTo });

  if (error) {
    throw error;
  }
}

export const updatePassword = async (password: string) => {
  const { error } = await supabase.auth.updateUser({ password: password });

  if (error) {
    throw error;
  }
}

export const signOut = async (dispatch: any) => {
  const { error } = await supabase.auth.signOut();

  if (error) {
    throw error;
  }

  dispatch(clearAuth());
}

export const addNewText = async (payload: AddNewTextDto) => {
  const { data, error } = await supabase.from('documents').insert([payload]);

  if (error) {
    throw error;
  }

  return data;
}

export const editText = async (payload: AddNewTextDto, id: string) => {
  const { data, error } = await supabase.from('documents').update(payload).eq('id', id);

  if (error) {
    throw error;
  }

  return data;
}

export const getUserTexts = async (email: string) => {
  const { data, error } = await supabase.from('documents').select('*').eq('email', email);

  if (error) {
    throw error;
  }

  return data;
}

export const getTextById = async (id: string) => {
  const { data, error } = await supabase.from('documents').select('*').eq('id', id);

  if (error) {
    throw error;
  }

  return data;
}

export const deleteText = async (id: string) => {
  const { data, error } = await supabase.from('documents').delete().eq('id', id);

  if (error) {
    throw error;
  }

  return data;
}