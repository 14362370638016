import { WordDefinition } from "./interfaces/dictionary";
import { DocumentInterface, WordDictionaryData } from "./interfaces/document";
import { ExerciseInterface } from "./interfaces/exercise";

// export const documentsData: DocumentInterface[] = [
//   {
//     id: 1,
//     isFavorite: true,
//     isCompleted: true,
//     title: {
//       ch: "沙利文访华会见王毅，或为最后一次'习拜会'铺路",
//       py: "Shā lì wén fǎng huá huìjiàn wáng yí, huò wèi zuìhòu yī cì 'xí bài huì' pū lù",
//       en: "Top Biden Aide Visits China to Reinforce U.S. Strategy"
//     },
//     content: {
//       ch: "沙利文访华会见王毅，或为最后一次'习拜会'铺路",
//       py: "Shā lì wén fǎng huá huìjiàn wáng yí, huò wèi zuìhòu yī cì 'xí bài huì' pū lù",
//       en: "Top Biden Aide Visits China to Reinforce U.S. Strategy"
//     },
//     texts: [
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//     ]
//   },
//   {
//     id: 2,
//     isFavorite: false,
//     isCompleted: true,
//     title: {
//       ch: "沙利文访华会见王毅，或为最后一次'习拜会'铺路",
//       py: "Shā lì wén fǎng huá huìjiàn wáng yí, huò wèi zuìhòu yī cì 'xí bài huì' pū lù",
//       en: "Top Biden Aide Visits China to Reinforce U.S. Strategy"
//     },
//     content: {
//       ch: "沙利文访华会见王毅，或为最后一次'习拜会'铺路",
//       py: "Shā lì wén fǎng huá huìjiàn wáng yí, huò wèi zuìhòu yī cì 'xí bài huì' pū lù",
//       en: "Top Biden Aide Visits China to Reinforce U.S. Strategy"
//     },
//     texts: [
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//     ]
//   },
//   {
//     id: 3,
//     isFavorite: false,
//     isCompleted: true,
//     title: {
//       ch: "沙利文访华会见王毅，或为最后一次'习拜会'铺路",
//       py: "Shā lì wén fǎng huá huìjiàn wáng yí, huò wèi zuìhòu yī cì 'xí bài huì' pū lù",
//       en: "Top Biden Aide Visits China to Reinforce U.S. Strategy"
//     },
//     content: {
//       ch: "沙利文访华会见王毅，或为最后一次'习拜会'铺路",
//       py: "Shā lì wén fǎng huá huìjiàn wáng yí, huò wèi zuìhòu yī cì 'xí bài huì' pū lù",
//       en: "Top Biden Aide Visits China to Reinforce U.S. Strategy"
//     },
//     texts: [
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//     ]
//   },
//   {
//     id: 4,
//     isFavorite: false,
//     isCompleted: true,
//     title: {
//       ch: "沙利文访华会见王毅，或为最后一次'习拜会'铺路",
//       py: "Shā lì wén fǎng huá huìjiàn wáng yí, huò wèi zuìhòu yī cì 'xí bài huì' pū lù",
//       en: "Top Biden Aide Visits China to Reinforce U.S. Strategy"
//     },
//     content: {
//       ch: "沙利文访华会见王毅，或为最后一次'习拜会'铺路",
//       py: "Shā lì wén fǎng huá huìjiàn wáng yí, huò wèi zuìhòu yī cì 'xí bài huì' pū lù",
//       en: "Top Biden Aide Visits China to Reinforce U.S. Strategy"
//     },
//     texts: [
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//     ]
//   },
//   {
//     id: 5,
//     isFavorite: false,
//     isCompleted: true,
//     title: {
//       ch: "沙利文访华会见王毅，或为最后一次'习拜会'铺路",
//       py: "Shā lì wén fǎng huá huìjiàn wáng yí, huò wèi zuìhòu yī cì 'xí bài huì' pū lù",
//       en: "Top Biden Aide Visits China to Reinforce U.S. Strategy"
//     },
//     content: {
//       ch: "沙利文访华会见王毅，或为最后一次'习拜会'铺路",
//       py: "Shā lì wén fǎng huá huìjiàn wáng yí, huò wèi zuìhòu yī cì 'xí bài huì' pū lù",
//       en: "Top Biden Aide Visits China to Reinforce U.S. Strategy"
//     },
//     texts: [
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//     ]
//   },
//   {
//     id: 6,
//     isFavorite: false,
//     isCompleted: true,
//     title: {
//       ch: "沙利文访华会见王毅，或为最后一次'习拜会'铺路",
//       py: "Shā lì wén fǎng huá huìjiàn wáng yí, huò wèi zuìhòu yī cì 'xí bài huì' pū lù",
//       en: "Top Biden Aide Visits China to Reinforce U.S. Strategy"
//     },
//     content: {
//       ch: "沙利文访华会见王毅，或为最后一次'习拜会'铺路",
//       py: "Shā lì wén fǎng huá huìjiàn wáng yí, huò wèi zuìhòu yī cì 'xí bài huì' pū lù",
//       en: "Top Biden Aide Visits China to Reinforce U.S. Strategy"
//     },
//     texts: [
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//     ]
//   },
//   {
//     id: 7,
//     isFavorite: false,
//     isCompleted: true,
//     title: {
//       ch: "沙利文访华会见王毅，或为最后一次'习拜会'铺路",
//       py: "Shā lì wén fǎng huá huìjiàn wáng yí, huò wèi zuìhòu yī cì 'xí bài huì' pū lù",
//       en: "Top Biden Aide Visits China to Reinforce U.S. Strategy"
//     },
//     content: {
//       ch: "沙利文访华会见王毅，或为最后一次'习拜会'铺路",
//       py: "Shā lì wén fǎng huá huìjiàn wáng yí, huò wèi zuìhòu yī cì 'xí bài huì' pū lù",
//       en: "Top Biden Aide Visits China to Reinforce U.S. Strategy"
//     },
//     texts: [
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//     ]
//   },
//   {
//     id: 8,
//     isFavorite: false,
//     isCompleted: true,
//     title: {
//       ch: "沙利文访华会见王毅，或为最后一次'习拜会'铺路",
//       py: "Shā lì wén fǎng huá huìjiàn wáng yí, huò wèi zuìhòu yī cì 'xí bài huì' pū lù",
//       en: "Top Biden Aide Visits China to Reinforce U.S. Strategy"
//     },
//     content: {
//       ch: "沙利文访华会见王毅，或为最后一次'习拜会'铺路",
//       py: "Shā lì wén fǎng huá huìjiàn wáng yí, huò wèi zuìhòu yī cì 'xí bài huì' pū lù",
//       en: "Top Biden Aide Visits China to Reinforce U.S. Strategy"
//     },
//     texts: [
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//     ]
//   },
//   {
//     id: 9,
//     isFavorite: false,
//     isCompleted: true,
//     title: {
//       ch: "沙利文访华会见王毅，或为最后一次'习拜会'铺路",
//       py: "Shā lì wén fǎng huá huìjiàn wáng yí, huò wèi zuìhòu yī cì 'xí bài huì' pū lù",
//       en: "Top Biden Aide Visits China to Reinforce U.S. Strategy"
//     },
//     content: {
//       ch: "沙利文访华会见王毅，或为最后一次'习拜会'铺路",
//       py: "Shā lì wén fǎng huá huìjiàn wáng yí, huò wèi zuìhòu yī cì 'xí bài huì' pū lù",
//       en: "Top Biden Aide Visits China to Reinforce U.S. Strategy"
//     },
//     texts: [
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//     ]
//   },
//   {
//     id: 10,
//     isFavorite: false,
//     isCompleted: true,
//     title: {
//       ch: "沙利文访华会见王毅，或为最后一次'习拜会'铺路",
//       py: "Shā lì wén fǎng huá huìjiàn wáng yí, huò wèi zuìhòu yī cì 'xí bài huì' pū lù",
//       en: "Top Biden Aide Visits China to Reinforce U.S. Strategy"
//     },
//     content: {
//       ch: "沙利文访华会见王毅，或为最后一次'习拜会'铺路",
//       py: "Shā lì wén fǎng huá huìjiàn wáng yí, huò wèi zuìhòu yī cì 'xí bài huì' pū lù",
//       en: "Top Biden Aide Visits China to Reinforce U.S. Strategy"
//     },
//     texts: [
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//     ]
//   },
//   {
//     id: 11,
//     isFavorite: false,
//     isCompleted: true,
//     title: {
//       ch: "沙利文访华会见王毅，或为最后一次'习拜会'铺路",
//       py: "Shā lì wén fǎng huá huìjiàn wáng yí, huò wèi zuìhòu yī cì 'xí bài huì' pū lù",
//       en: "Top Biden Aide Visits China to Reinforce U.S. Strategy"
//     },
//     content: {
//       ch: "沙利文访华会见王毅，或为最后一次'习拜会'铺路",
//       py: "Shā lì wén fǎng huá huìjiàn wáng yí, huò wèi zuìhòu yī cì 'xí bài huì' pū lù",
//       en: "Top Biden Aide Visits China to Reinforce U.S. Strategy"
//     },
//     texts: [
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//     ]
//   },
//   {
//     id: 12,
//     isFavorite: false,
//     isCompleted: true,
//     title: {
//       ch: "沙利文访华会见王毅，或为最后一次'习拜会'铺路",
//       py: "Shā lì wén fǎng huá huìjiàn wáng yí, huò wèi zuìhòu yī cì 'xí bài huì' pū lù",
//       en: "Top Biden Aide Visits China to Reinforce U.S. Strategy"
//     },
//     content: {
//       ch: "沙利文访华会见王毅，或为最后一次'习拜会'铺路",
//       py: "Shā lì wén fǎng huá huìjiàn wáng yí, huò wèi zuìhòu yī cì 'xí bài huì' pū lù",
//       en: "Top Biden Aide Visits China to Reinforce U.S. Strategy"
//     },
//     texts: [
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//     ]
//   },
//   {
//     id: 13,
//     isFavorite: false,
//     isCompleted: true,
//     title: {
//       ch: "沙利文访华会见王毅，或为最后一次'习拜会'铺路",
//       py: "Shā lì wén fǎng huá huìjiàn wáng yí, huò wèi zuìhòu yī cì 'xí bài huì' pū lù",
//       en: "Top Biden Aide Visits China to Reinforce U.S. Strategy"
//     },
//     content: {
//       ch: "沙利文访华会见王毅，或为最后一次'习拜会'铺路",
//       py: "Shā lì wén fǎng huá huìjiàn wáng yí, huò wèi zuìhòu yī cì 'xí bài huì' pū lù",
//       en: "Top Biden Aide Visits China to Reinforce U.S. Strategy"
//     },
//     texts: [
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//       {
//         ch: "在拜登政府剩下的几个月时间里，杰克·沙利文再次大力推动与中国的合作。他于周二抵达北京，参加旨在表明美中能管控双方分歧的会谈.",
//         py: "Zài bài dēng zhèngfǔ shèng xià de jǐ ge yuè shíjiān lǐ, jiékè·shā lì wén zài cì dàlì tuīdòng yǔ zhōngguó de hézuò. Tā yú zhōu'èr dídá běijīng, cānjiā zhǐ zài biǎomíng měi zhōng néng guǎn kòng shuāngfāng fēnqí de huìtán.",
//         en: "Making another heavy push to work with China in the waning months of the Biden administration, Jake Sullivan arrived in Beijing on Tuesday for talks aimed at showing that the United States and China can manage their differences."
//       },
//     ]
//   }
// ];

export const documentsData: DocumentInterface[] = [
  {
    "id": 1,
    "metadata": {
      "id": "unique_text_id_20241004",
      "title": "关于纳斯鲁拉之死的报道",
      "source": "示例新闻",
      "difficulty": 4,
      "tags": ["新闻", "政治", "中东"],
      "createdAt": "2024-10-04T14:30:00Z",
      "isFavorite": false,
      "isCompleted": true,
    },
    "content": {
      "fullText": "上周六，哀悼纳斯鲁拉的伊朗人聚集在德黑兰的几个地点，包括巴勒斯坦广场，他们挥舞着真主党的旗帜，随着扩音器播放出宗教歌曲，捶打着胸膛。\n伊拉克总理穆罕默德·希亚·苏丹尼宣布伊拉克全国哀悼三天。\n哈马斯发声明称，以色列的暗杀'只会让巴勒斯坦和黎巴嫩的抵抗更坚定、更持久'。",
      "fullTranslation": "Last Saturday, Iranians mourning Nasrallah gathered at several locations in Tehran, including Palestine Square, waving Hezbollah flags, beating their chests as loudspeakers played religious songs.\nIraqi Prime Minister Mohammed Shia al-Sudani declared three days of national mourning in Iraq.\nHamas issued a statement saying Israel's assassination 'will only make the resistance in Palestine and Lebanon more determined and lasting'.",
      "paragraphs": [
        {
          "id": "paragraph1",
          "text": "上周六，哀悼纳斯鲁拉的伊朗人聚集在德黑兰的几个地点，包括巴勒斯坦广场，他们挥舞着真主党的旗帜，随着扩音器播放出宗教歌曲，捶打着胸膛。",
          "translation": "Last Saturday, Iranians mourning Nasrallah gathered at several locations in Tehran, including Palestine Square, waving Hezbollah flags, beating their chests as loudspeakers played religious songs.",
          "sentences": [
            {
              "id": "sentence1",
              "text": "上周六，哀悼纳斯鲁拉的伊朗人聚集在德黑兰的几个地点，包括巴勒斯坦广场，他们挥舞着真主党的旗帜，随着扩音器播放出宗教歌曲，捶打着胸膛。",
              "translation": "Last Saturday, Iranians mourning Nasrallah gathered at several locations in Tehran, including Palestine Square, waving Hezbollah flags, beating their chests as loudspeakers played religious songs.",
              "words": [
                {
                  "text": "上周六",
                  "index": 0,
                  "pinyin": "shàng zhōu liù",
                  "partOfSpeech": "noun",
                  "translation": "last Saturday"
                },
                {
                  "text": "，",
                  "index": 1,
                  "pinyin": "",
                  "partOfSpeech": "punctuation",
                  "translation": ","
                },
                {
                  "text": "哀悼",
                  "index": 2,
                  "pinyin": "āidào",
                  "partOfSpeech": "verb",
                  "translation": "mourn"
                },
                {
                  "text": "纳斯鲁拉",
                  "index": 3,
                  "pinyin": "Nàsīlǔlā",
                  "partOfSpeech": "proper noun",
                  "translation": "Nasrallah"
                },
                {
                  "text": "的",
                  "index": 4,
                  "pinyin": "de",
                  "partOfSpeech": "particle",
                  "translation": "of"
                },
                {
                  "text": "伊朗人",
                  "index": 5,
                  "pinyin": "Yīlǎng rén",
                  "partOfSpeech": "noun",
                  "translation": "Iranians"
                },
                {
                  "text": "聚集",
                  "index": 6,
                  "pinyin": "jùjí",
                  "partOfSpeech": "verb",
                  "translation": "gather"
                },
                {
                  "text": "在",
                  "index": 7,
                  "pinyin": "zài",
                  "partOfSpeech": "preposition",
                  "translation": "at"
                },
                {
                  "text": "德黑兰",
                  "index": 8,
                  "pinyin": "Déhēilán",
                  "partOfSpeech": "proper noun",
                  "translation": "Tehran"
                },
                {
                  "text": "的",
                  "index": 9,
                  "pinyin": "de",
                  "partOfSpeech": "particle",
                  "translation": "of"
                },
                {
                  "text": "几个",
                  "index": 10,
                  "pinyin": "jǐ gè",
                  "partOfSpeech": "numeral",
                  "translation": "several"
                },
                {
                  "text": "地点",
                  "index": 11,
                  "pinyin": "dìdiǎn",
                  "partOfSpeech": "noun",
                  "translation": "locations"
                },
                {
                  "text": "，",
                  "index": 12,
                  "pinyin": "",
                  "partOfSpeech": "punctuation",
                  "translation": ","
                },
                {
                  "text": "包括",
                  "index": 13,
                  "pinyin": "bāokuò",
                  "partOfSpeech": "verb",
                  "translation": "including"
                },
                {
                  "text": "巴勒斯坦",
                  "index": 14,
                  "pinyin": "Bālèsītǎn",
                  "partOfSpeech": "proper noun",
                  "translation": "Palestine"
                },
                {
                  "text": "广场",
                  "index": 15,
                  "pinyin": "guǎngchǎng",
                  "partOfSpeech": "noun",
                  "translation": "Square"
                },
                {
                  "text": "，",
                  "index": 16,
                  "pinyin": "",
                  "partOfSpeech": "punctuation",
                  "translation": ","
                },
                {
                  "text": "他们",
                  "index": 17,
                  "pinyin": "tāmen",
                  "partOfSpeech": "pronoun",
                  "translation": "they"
                },
                {
                  "text": "挥舞",
                  "index": 18,
                  "pinyin": "huīwǔ",
                  "partOfSpeech": "verb",
                  "translation": "wave"
                },
                {
                  "text": "着",
                  "index": 19,
                  "pinyin": "zhe",
                  "partOfSpeech": "particle",
                  "translation": "-ing"
                },
                {
                  "text": "真主党",
                  "index": 20,
                  "pinyin": "Zhēnzhǔdǎng",
                  "partOfSpeech": "proper noun",
                  "translation": "Hezbollah"
                },
                {
                  "text": "的",
                  "index": 21,
                  "pinyin": "de",
                  "partOfSpeech": "particle",
                  "translation": "of"
                },
                {
                  "text": "旗帜",
                  "index": 22,
                  "pinyin": "qízhì",
                  "partOfSpeech": "noun",
                  "translation": "flags"
                },
                {
                  "text": "，",
                  "index": 23,
                  "pinyin": "",
                  "partOfSpeech": "punctuation",
                  "translation": ","
                },
                {
                  "text": "随着",
                  "index": 24,
                  "pinyin": "suízhe",
                  "partOfSpeech": "preposition",
                  "translation": "as"
                },
                {
                  "text": "扩音器",
                  "index": 25,
                  "pinyin": "kuòyīnqì",
                  "partOfSpeech": "noun",
                  "translation": "loudspeakers"
                },
                {
                  "text": "播放",
                  "index": 26,
                  "pinyin": "bòfàng",
                  "partOfSpeech": "verb",
                  "translation": "play"
                },
                {
                  "text": "出",
                  "index": 27,
                  "pinyin": "chū",
                  "partOfSpeech": "verb",
                  "translation": "out"
                },
                {
                  "text": "宗教",
                  "index": 28,
                  "pinyin": "zōngjiào",
                  "partOfSpeech": "noun",
                  "translation": "religious"
                },
                {
                  "text": "歌曲",
                  "index": 29,
                  "pinyin": "gēqǔ",
                  "partOfSpeech": "noun",
                  "translation": "songs"
                },
                {
                  "text": "，",
                  "index": 30,
                  "pinyin": "",
                  "partOfSpeech": "punctuation",
                  "translation": ","
                },
                {
                  "text": "捶打",
                  "index": 31,
                  "pinyin": "chuídǎ",
                  "partOfSpeech": "verb",
                  "translation": "beat"
                },
                {
                  "text": "着",
                  "index": 32,
                  "pinyin": "zhe",
                  "partOfSpeech": "particle",
                  "translation": "-ing"
                },
                {
                  "text": "胸膛",
                  "index": 33,
                  "pinyin": "xiōngtáng",
                  "partOfSpeech": "noun",
                  "translation": "chests"
                },
                {
                  "text": "。",
                  "index": 34,
                  "pinyin": "",
                  "partOfSpeech": "punctuation",
                  "translation": "."
                }
              ]
            }
          ]
        },
        {
          "id": "paragraph2",
          "text": "伊拉克总理穆罕默德·希亚·苏丹尼宣布伊拉克全国哀悼三天。",
          "translation": "Iraqi Prime Minister Mohammed Shia al-Sudani declared three days of national mourning in Iraq.",
          "sentences": [
            {
              "id": "sentence2",
              "text": "伊拉克总理穆罕默德·希亚·苏丹尼宣布伊拉克全国哀悼三天。",
              "translation": "Iraqi Prime Minister Mohammed Shia al-Sudani declared three days of national mourning in Iraq.",
              "words": [
                {
                  "text": "伊拉克",
                  "index": 0,
                  "pinyin": "Yīlākè",
                  "partOfSpeech": "proper noun",
                  "translation": "Iraq"
                },
                {
                  "text": "总理",
                  "index": 1,
                  "pinyin": "zǒnglǐ",
                  "partOfSpeech": "noun",
                  "translation": "Prime Minister"
                },
                {
                  "text": "穆罕默德·希亚·苏丹尼",
                  "index": 2,
                  "pinyin": "Mùhǎnmòdé·Xīyà·Sūdānní",
                  "partOfSpeech": "proper noun",
                  "translation": "Mohammed Shia al-Sudani"
                },
                {
                  "text": "宣布",
                  "index": 3,
                  "pinyin": "xuānbù",
                  "partOfSpeech": "verb",
                  "translation": "declared"
                },
                {
                  "text": "伊拉克",
                  "index": 4,
                  "pinyin": "Yīlākè",
                  "partOfSpeech": "proper noun",
                  "translation": "Iraq"
                },
                {
                  "text": "全国",
                  "index": 5,
                  "pinyin": "quánguó",
                  "partOfSpeech": "noun",
                  "translation": "national"
                },
                {
                  "text": "哀悼",
                  "index": 6,
                  "pinyin": "āidào",
                  "partOfSpeech": "verb",
                  "translation": "mourning"
                },
                {
                  "text": "三",
                  "index": 7,
                  "pinyin": "sān",
                  "partOfSpeech": "numeral",
                  "translation": "three"
                },
                {
                  "text": "天",
                  "index": 8,
                  "pinyin": "tiān",
                  "partOfSpeech": "noun",
                  "translation": "days"
                },
                {
                  "text": "。",
                  "index": 9,
                  "pinyin": "",
                  "partOfSpeech": "punctuation",
                  "translation": "."
                }
              ]
            }
          ]
        },
        {
          "id": "paragraph3",
          "text": "哈马斯发声明称，以色列的暗杀'只会让巴勒斯坦和黎巴嫩的抵抗更坚定、更持久'。",
          "translation": "Hamas issued a statement saying Israel's assassination 'will only make the resistance in Palestine and Lebanon more determined and lasting'.",
          "sentences": [
            {
              "id": "sentence3",
              "text": "哈马斯发声明称，以色列的暗杀'只会让巴勒斯坦和黎巴嫩的抵抗更坚定、更持久'。",
              "translation": "Hamas issued a statement saying Israel's assassination 'will only make the resistance in Palestine and Lebanon more determined and lasting'.",
              "words": [
                {
                  "text": "哈马斯",
                  "index": 0,
                  "pinyin": "Hāmǎsī",
                  "partOfSpeech": "proper noun",
                  "translation": "Hamas"
                },
                {
                  "text": "发",
                  "index": 1,
                  "pinyin": "fā",
                  "partOfSpeech": "verb",
                  "translation": "issue"
                },
                {
                  "text": "声明",
                  "index": 2,
                  "pinyin": "shēngmíng",
                  "partOfSpeech": "noun",
                  "translation": "statement"
                },
                {
                  "text": "称",
                  "index": 3,
                  "pinyin": "chēng",
                  "partOfSpeech": "verb",
                  "translation": "say"
                },
                {
                  "text": "，",
                  "index": 4,
                  "pinyin": "",
                  "partOfSpeech": "punctuation",
                  "translation": ","
                },
                {
                  "text": "以色列",
                  "index": 5,
                  "pinyin": "Yǐsèliè",
                  "partOfSpeech": "proper noun",
                  "translation": "Israel"
                },
                {
                  "text": "的",
                  "index": 6,
                  "pinyin": "de",
                  "partOfSpeech": "particle",
                  "translation": "'s"
                },
                {
                  "text": "暗杀",
                  "index": 7,
                  "pinyin": "ànshā",
                  "partOfSpeech": "noun",
                  "translation": "assassination"
                },
                {
                  "text": "\"",
                  "index": 8,
                  "pinyin": "",
                  "partOfSpeech": "punctuation",
                  "translation": "\""
                },
                {
                  "text": "只",
                  "index": 9,
                  "pinyin": "zhǐ",
                  "partOfSpeech": "adverb",
                  "translation": "only"
                },
                {
                  "text": "会",
                  "index": 10,
                  "pinyin": "huì",
                  "partOfSpeech": "auxiliary",
                  "translation": "will"
                },
                {
                  "text": "让",
                  "index": 11,
                  "pinyin": "ràng",
                  "partOfSpeech": "verb",
                  "translation": "make"
                },
                {
                  "text": "巴勒斯坦",
                  "index": 12,
                  "pinyin": "Bālèsītǎn",
                  "partOfSpeech": "proper noun",
                  "translation": "Palestine"
                },
                {
                  "text": "和",
                  "index": 13,
                  "pinyin": "hé",
                  "partOfSpeech": "conjunction",
                  "translation": "and"
                },
                {
                  "text": "黎巴嫩",
                  "index": 14,
                  "pinyin": "Líbānèn",
                  "partOfSpeech": "proper noun",
                  "translation": "Lebanon"
                },
                {
                  "text": "的",
                  "index": 15,
                  "pinyin": "de",
                  "partOfSpeech": "particle",
                  "translation": "'s"
                },
                {
                  "text": "抵抗",
                  "index": 16,
                  "pinyin": "dǐkàng",
                  "partOfSpeech": "noun",
                  "translation": "resistance"
                },
                {
                  "text": "更",
                  "index": 17,
                  "pinyin": "gèng",
                  "partOfSpeech": "adverb",
                  "translation": "more"
                },
                {
                  "text": "坚定",
                  "index": 18,
                  "pinyin": "jiāndìng",
                  "partOfSpeech": "adjective",
                  "translation": "determined"
                },
                {
                  "text": "、",
                  "index": 19,
                  "pinyin": "",
                  "partOfSpeech": "punctuation",
                  "translation": ","
                },
                {
                  "text": "更",
                  "index": 20,
                  "pinyin": "gèng",
                  "partOfSpeech": "adverb",
                  "translation": "more"
                },
                {
                  "text": "持久",
                  "index": 21,
                  "pinyin": "chíjiǔ",
                  "partOfSpeech": "adjective",
                  "translation": "lasting"
                },
                {
                  "text": "\"",
                  "index": 22,
                  "pinyin": "",
                  "partOfSpeech": "punctuation",
                  "translation": "\""
                },
                {
                  "text": "。",
                  "index": 23,
                  "pinyin": "",
                  "partOfSpeech": "punctuation",
                  "translation": "."
                }
              ]
            }
          ]
        }
      ]
    }
  },
  {
    "id": 2,
    "metadata": {
      "id": "unique_text_id_20241004",
      "title": "关于纳斯鲁拉之死的报道",
      "source": "示例新闻",
      "difficulty": 4,
      "tags": ["新闻", "政治", "中东"],
      "createdAt": "2024-10-04T14:30:00Z",
      "isFavorite": false,
      "isCompleted": true,
    },
    "content": {
      "fullText": "上周六，哀悼纳斯鲁拉的伊朗人聚集在德黑兰的几个地点，包括巴勒斯坦广场，他们挥舞着真主党的旗帜，随着扩音器播放出宗教歌曲，捶打着胸膛。\n伊拉克总理穆罕默德·希亚·苏丹尼宣布伊拉克全国哀悼三天。\n哈马斯发声明称，以色列的暗杀'只会让巴勒斯坦和黎巴嫩的抵抗更坚定、更持久'。",
      "fullTranslation": "Last Saturday, Iranians mourning Nasrallah gathered at several locations in Tehran, including Palestine Square, waving Hezbollah flags, beating their chests as loudspeakers played religious songs.\nIraqi Prime Minister Mohammed Shia al-Sudani declared three days of national mourning in Iraq.\nHamas issued a statement saying Israel's assassination 'will only make the resistance in Palestine and Lebanon more determined and lasting'.",
      "paragraphs": [
        {
          "id": "paragraph1",
          "text": "上周六，哀悼纳斯鲁拉的伊朗人聚集在德黑兰的几个地点，包括巴勒斯坦广场，他们挥舞着真主党的旗帜，随着扩音器播放出宗教歌曲，捶打着胸膛。",
          "translation": "Last Saturday, Iranians mourning Nasrallah gathered at several locations in Tehran, including Palestine Square, waving Hezbollah flags, beating their chests as loudspeakers played religious songs.",
          "sentences": [
            {
              "id": "sentence1",
              "text": "上周六，哀悼纳斯鲁拉的伊朗人聚集在德黑兰的几个地点，包括巴勒斯坦广场，他们挥舞着真主党的旗帜，随着扩音器播放出宗教歌曲，捶打着胸膛。",
              "translation": "Last Saturday, Iranians mourning Nasrallah gathered at several locations in Tehran, including Palestine Square, waving Hezbollah flags, beating their chests as loudspeakers played religious songs.",
              "words": [
                {
                  "text": "上周六",
                  "index": 0,
                  "pinyin": "shàng zhōu liù",
                  "partOfSpeech": "noun",
                  "translation": "last Saturday"
                },
                {
                  "text": "，",
                  "index": 1,
                  "pinyin": "",
                  "partOfSpeech": "punctuation",
                  "translation": ","
                },
                {
                  "text": "哀悼",
                  "index": 2,
                  "pinyin": "āidào",
                  "partOfSpeech": "verb",
                  "translation": "mourn"
                },
                {
                  "text": "纳斯鲁拉",
                  "index": 3,
                  "pinyin": "Nàsīlǔlā",
                  "partOfSpeech": "proper noun",
                  "translation": "Nasrallah"
                },
                {
                  "text": "的",
                  "index": 4,
                  "pinyin": "de",
                  "partOfSpeech": "particle",
                  "translation": "of"
                },
                {
                  "text": "伊朗人",
                  "index": 5,
                  "pinyin": "Yīlǎng rén",
                  "partOfSpeech": "noun",
                  "translation": "Iranians"
                },
                {
                  "text": "聚集",
                  "index": 6,
                  "pinyin": "jùjí",
                  "partOfSpeech": "verb",
                  "translation": "gather"
                },
                {
                  "text": "在",
                  "index": 7,
                  "pinyin": "zài",
                  "partOfSpeech": "preposition",
                  "translation": "at"
                },
                {
                  "text": "德黑兰",
                  "index": 8,
                  "pinyin": "Déhēilán",
                  "partOfSpeech": "proper noun",
                  "translation": "Tehran"
                },
                {
                  "text": "的",
                  "index": 9,
                  "pinyin": "de",
                  "partOfSpeech": "particle",
                  "translation": "of"
                },
                {
                  "text": "几个",
                  "index": 10,
                  "pinyin": "jǐ gè",
                  "partOfSpeech": "numeral",
                  "translation": "several"
                },
                {
                  "text": "地点",
                  "index": 11,
                  "pinyin": "dìdiǎn",
                  "partOfSpeech": "noun",
                  "translation": "locations"
                },
                {
                  "text": "，",
                  "index": 12,
                  "pinyin": "",
                  "partOfSpeech": "punctuation",
                  "translation": ","
                },
                {
                  "text": "包括",
                  "index": 13,
                  "pinyin": "bāokuò",
                  "partOfSpeech": "verb",
                  "translation": "including"
                },
                {
                  "text": "巴勒斯坦",
                  "index": 14,
                  "pinyin": "Bālèsītǎn",
                  "partOfSpeech": "proper noun",
                  "translation": "Palestine"
                },
                {
                  "text": "广场",
                  "index": 15,
                  "pinyin": "guǎngchǎng",
                  "partOfSpeech": "noun",
                  "translation": "Square"
                },
                {
                  "text": "，",
                  "index": 16,
                  "pinyin": "",
                  "partOfSpeech": "punctuation",
                  "translation": ","
                },
                {
                  "text": "他们",
                  "index": 17,
                  "pinyin": "tāmen",
                  "partOfSpeech": "pronoun",
                  "translation": "they"
                },
                {
                  "text": "挥舞",
                  "index": 18,
                  "pinyin": "huīwǔ",
                  "partOfSpeech": "verb",
                  "translation": "wave"
                },
                {
                  "text": "着",
                  "index": 19,
                  "pinyin": "zhe",
                  "partOfSpeech": "particle",
                  "translation": "-ing"
                },
                {
                  "text": "真主党",
                  "index": 20,
                  "pinyin": "Zhēnzhǔdǎng",
                  "partOfSpeech": "proper noun",
                  "translation": "Hezbollah"
                },
                {
                  "text": "的",
                  "index": 21,
                  "pinyin": "de",
                  "partOfSpeech": "particle",
                  "translation": "of"
                },
                {
                  "text": "旗帜",
                  "index": 22,
                  "pinyin": "qízhì",
                  "partOfSpeech": "noun",
                  "translation": "flags"
                },
                {
                  "text": "，",
                  "index": 23,
                  "pinyin": "",
                  "partOfSpeech": "punctuation",
                  "translation": ","
                },
                {
                  "text": "随着",
                  "index": 24,
                  "pinyin": "suízhe",
                  "partOfSpeech": "preposition",
                  "translation": "as"
                },
                {
                  "text": "扩音器",
                  "index": 25,
                  "pinyin": "kuòyīnqì",
                  "partOfSpeech": "noun",
                  "translation": "loudspeakers"
                },
                {
                  "text": "播放",
                  "index": 26,
                  "pinyin": "bòfàng",
                  "partOfSpeech": "verb",
                  "translation": "play"
                },
                {
                  "text": "出",
                  "index": 27,
                  "pinyin": "chū",
                  "partOfSpeech": "verb",
                  "translation": "out"
                },
                {
                  "text": "宗教",
                  "index": 28,
                  "pinyin": "zōngjiào",
                  "partOfSpeech": "noun",
                  "translation": "religious"
                },
                {
                  "text": "歌曲",
                  "index": 29,
                  "pinyin": "gēqǔ",
                  "partOfSpeech": "noun",
                  "translation": "songs"
                },
                {
                  "text": "，",
                  "index": 30,
                  "pinyin": "",
                  "partOfSpeech": "punctuation",
                  "translation": ","
                },
                {
                  "text": "捶打",
                  "index": 31,
                  "pinyin": "chuídǎ",
                  "partOfSpeech": "verb",
                  "translation": "beat"
                },
                {
                  "text": "着",
                  "index": 32,
                  "pinyin": "zhe",
                  "partOfSpeech": "particle",
                  "translation": "-ing"
                },
                {
                  "text": "胸膛",
                  "index": 33,
                  "pinyin": "xiōngtáng",
                  "partOfSpeech": "noun",
                  "translation": "chests"
                },
                {
                  "text": "。",
                  "index": 34,
                  "pinyin": "",
                  "partOfSpeech": "punctuation",
                  "translation": "."
                }
              ]
            }
          ]
        },
        {
          "id": "paragraph2",
          "text": "伊拉克总理穆罕默德·希亚·苏丹尼宣布伊拉克全国哀悼三天。",
          "translation": "Iraqi Prime Minister Mohammed Shia al-Sudani declared three days of national mourning in Iraq.",
          "sentences": [
            {
              "id": "sentence2",
              "text": "伊拉克总理穆罕默德·希亚·苏丹尼宣布伊拉克全国哀悼三天。",
              "translation": "Iraqi Prime Minister Mohammed Shia al-Sudani declared three days of national mourning in Iraq.",
              "words": [
                {
                  "text": "伊拉克",
                  "index": 0,
                  "pinyin": "Yīlākè",
                  "partOfSpeech": "proper noun",
                  "translation": "Iraq"
                },
                {
                  "text": "总理",
                  "index": 1,
                  "pinyin": "zǒnglǐ",
                  "partOfSpeech": "noun",
                  "translation": "Prime Minister"
                },
                {
                  "text": "穆罕默德·希亚·苏丹尼",
                  "index": 2,
                  "pinyin": "Mùhǎnmòdé·Xīyà·Sūdānní",
                  "partOfSpeech": "proper noun",
                  "translation": "Mohammed Shia al-Sudani"
                },
                {
                  "text": "宣布",
                  "index": 3,
                  "pinyin": "xuānbù",
                  "partOfSpeech": "verb",
                  "translation": "declared"
                },
                {
                  "text": "伊拉克",
                  "index": 4,
                  "pinyin": "Yīlākè",
                  "partOfSpeech": "proper noun",
                  "translation": "Iraq"
                },
                {
                  "text": "全国",
                  "index": 5,
                  "pinyin": "quánguó",
                  "partOfSpeech": "noun",
                  "translation": "national"
                },
                {
                  "text": "哀悼",
                  "index": 6,
                  "pinyin": "āidào",
                  "partOfSpeech": "verb",
                  "translation": "mourning"
                },
                {
                  "text": "三",
                  "index": 7,
                  "pinyin": "sān",
                  "partOfSpeech": "numeral",
                  "translation": "three"
                },
                {
                  "text": "天",
                  "index": 8,
                  "pinyin": "tiān",
                  "partOfSpeech": "noun",
                  "translation": "days"
                },
                {
                  "text": "。",
                  "index": 9,
                  "pinyin": "",
                  "partOfSpeech": "punctuation",
                  "translation": "."
                }
              ]
            }
          ]
        },
        {
          "id": "paragraph3",
          "text": "哈马斯发声明称，以色列的暗杀'只会让巴勒斯坦和黎巴嫩的抵抗更坚定、更持久'。",
          "translation": "Hamas issued a statement saying Israel's assassination 'will only make the resistance in Palestine and Lebanon more determined and lasting'.",
          "sentences": [
            {
              "id": "sentence3",
              "text": "哈马斯发声明称，以色列的暗杀'只会让巴勒斯坦和黎巴嫩的抵抗更坚定、更持久'。",
              "translation": "Hamas issued a statement saying Israel's assassination 'will only make the resistance in Palestine and Lebanon more determined and lasting'.",
              "words": [
                {
                  "text": "哈马斯",
                  "index": 0,
                  "pinyin": "Hāmǎsī",
                  "partOfSpeech": "proper noun",
                  "translation": "Hamas"
                },
                {
                  "text": "发",
                  "index": 1,
                  "pinyin": "fā",
                  "partOfSpeech": "verb",
                  "translation": "issue"
                },
                {
                  "text": "声明",
                  "index": 2,
                  "pinyin": "shēngmíng",
                  "partOfSpeech": "noun",
                  "translation": "statement"
                },
                {
                  "text": "称",
                  "index": 3,
                  "pinyin": "chēng",
                  "partOfSpeech": "verb",
                  "translation": "say"
                },
                {
                  "text": "，",
                  "index": 4,
                  "pinyin": "",
                  "partOfSpeech": "punctuation",
                  "translation": ","
                },
                {
                  "text": "以色列",
                  "index": 5,
                  "pinyin": "Yǐsèliè",
                  "partOfSpeech": "proper noun",
                  "translation": "Israel"
                },
                {
                  "text": "的",
                  "index": 6,
                  "pinyin": "de",
                  "partOfSpeech": "particle",
                  "translation": "'s"
                },
                {
                  "text": "暗杀",
                  "index": 7,
                  "pinyin": "ànshā",
                  "partOfSpeech": "noun",
                  "translation": "assassination"
                },
                {
                  "text": "\"",
                  "index": 8,
                  "pinyin": "",
                  "partOfSpeech": "punctuation",
                  "translation": "\""
                },
                {
                  "text": "只",
                  "index": 9,
                  "pinyin": "zhǐ",
                  "partOfSpeech": "adverb",
                  "translation": "only"
                },
                {
                  "text": "会",
                  "index": 10,
                  "pinyin": "huì",
                  "partOfSpeech": "auxiliary",
                  "translation": "will"
                },
                {
                  "text": "让",
                  "index": 11,
                  "pinyin": "ràng",
                  "partOfSpeech": "verb",
                  "translation": "make"
                },
                {
                  "text": "巴勒斯坦",
                  "index": 12,
                  "pinyin": "Bālèsītǎn",
                  "partOfSpeech": "proper noun",
                  "translation": "Palestine"
                },
                {
                  "text": "和",
                  "index": 13,
                  "pinyin": "hé",
                  "partOfSpeech": "conjunction",
                  "translation": "and"
                },
                {
                  "text": "黎巴嫩",
                  "index": 14,
                  "pinyin": "Líbānèn",
                  "partOfSpeech": "proper noun",
                  "translation": "Lebanon"
                },
                {
                  "text": "的",
                  "index": 15,
                  "pinyin": "de",
                  "partOfSpeech": "particle",
                  "translation": "'s"
                },
                {
                  "text": "抵抗",
                  "index": 16,
                  "pinyin": "dǐkàng",
                  "partOfSpeech": "noun",
                  "translation": "resistance"
                },
                {
                  "text": "更",
                  "index": 17,
                  "pinyin": "gèng",
                  "partOfSpeech": "adverb",
                  "translation": "more"
                },
                {
                  "text": "坚定",
                  "index": 18,
                  "pinyin": "jiāndìng",
                  "partOfSpeech": "adjective",
                  "translation": "determined"
                },
                {
                  "text": "、",
                  "index": 19,
                  "pinyin": "",
                  "partOfSpeech": "punctuation",
                  "translation": ","
                },
                {
                  "text": "更",
                  "index": 20,
                  "pinyin": "gèng",
                  "partOfSpeech": "adverb",
                  "translation": "more"
                },
                {
                  "text": "持久",
                  "index": 21,
                  "pinyin": "chíjiǔ",
                  "partOfSpeech": "adjective",
                  "translation": "lasting"
                },
                {
                  "text": "\"",
                  "index": 22,
                  "pinyin": "",
                  "partOfSpeech": "punctuation",
                  "translation": "\""
                },
                {
                  "text": "。",
                  "index": 23,
                  "pinyin": "",
                  "partOfSpeech": "punctuation",
                  "translation": "."
                }
              ]
            }
          ]
        }
      ]
    }
  },
  {
    "id": 3,
    "metadata": {
      "id": "unique_text_id_20241004",
      "title": "关于纳斯鲁拉之死的报道",
      "source": "示例新闻",
      "difficulty": 4,
      "tags": ["新闻", "政治", "中东"],
      "createdAt": "2024-10-04T14:30:00Z",
      "isFavorite": false,
      "isCompleted": true,
    },
    "content": {
      "fullText": "上周六，哀悼纳斯鲁拉的伊朗人聚集在德黑兰的几个地点，包括巴勒斯坦广场，他们挥舞着真主党的旗帜，随着扩音器播放出宗教歌曲，捶打着胸膛。\n伊拉克总理穆罕默德·希亚·苏丹尼宣布伊拉克全国哀悼三天。\n哈马斯发声明称，以色列的暗杀'只会让巴勒斯坦和黎巴嫩的抵抗更坚定、更持久'。",
      "fullTranslation": "Last Saturday, Iranians mourning Nasrallah gathered at several locations in Tehran, including Palestine Square, waving Hezbollah flags, beating their chests as loudspeakers played religious songs.\nIraqi Prime Minister Mohammed Shia al-Sudani declared three days of national mourning in Iraq.\nHamas issued a statement saying Israel's assassination 'will only make the resistance in Palestine and Lebanon more determined and lasting'.",
      "paragraphs": [
        {
          "id": "paragraph1",
          "text": "上周六，哀悼纳斯鲁拉的伊朗人聚集在德黑兰的几个地点，包括巴勒斯坦广场，他们挥舞着真主党的旗帜，随着扩音器播放出宗教歌曲，捶打着胸膛。",
          "translation": "Last Saturday, Iranians mourning Nasrallah gathered at several locations in Tehran, including Palestine Square, waving Hezbollah flags, beating their chests as loudspeakers played religious songs.",
          "sentences": [
            {
              "id": "sentence1",
              "text": "上周六，哀悼纳斯鲁拉的伊朗人聚集在德黑兰的几个地点，包括巴勒斯坦广场，他们挥舞着真主党的旗帜，随着扩音器播放出宗教歌曲，捶打着胸膛。",
              "translation": "Last Saturday, Iranians mourning Nasrallah gathered at several locations in Tehran, including Palestine Square, waving Hezbollah flags, beating their chests as loudspeakers played religious songs.",
              "words": [
                {
                  "text": "上周六",
                  "index": 0,
                  "pinyin": "shàng zhōu liù",
                  "partOfSpeech": "noun",
                  "translation": "last Saturday"
                },
                {
                  "text": "，",
                  "index": 1,
                  "pinyin": "",
                  "partOfSpeech": "punctuation",
                  "translation": ","
                },
                {
                  "text": "哀悼",
                  "index": 2,
                  "pinyin": "āidào",
                  "partOfSpeech": "verb",
                  "translation": "mourn"
                },
                {
                  "text": "纳斯鲁拉",
                  "index": 3,
                  "pinyin": "Nàsīlǔlā",
                  "partOfSpeech": "proper noun",
                  "translation": "Nasrallah"
                },
                {
                  "text": "的",
                  "index": 4,
                  "pinyin": "de",
                  "partOfSpeech": "particle",
                  "translation": "of"
                },
                {
                  "text": "伊朗人",
                  "index": 5,
                  "pinyin": "Yīlǎng rén",
                  "partOfSpeech": "noun",
                  "translation": "Iranians"
                },
                {
                  "text": "聚集",
                  "index": 6,
                  "pinyin": "jùjí",
                  "partOfSpeech": "verb",
                  "translation": "gather"
                },
                {
                  "text": "在",
                  "index": 7,
                  "pinyin": "zài",
                  "partOfSpeech": "preposition",
                  "translation": "at"
                },
                {
                  "text": "德黑兰",
                  "index": 8,
                  "pinyin": "Déhēilán",
                  "partOfSpeech": "proper noun",
                  "translation": "Tehran"
                },
                {
                  "text": "的",
                  "index": 9,
                  "pinyin": "de",
                  "partOfSpeech": "particle",
                  "translation": "of"
                },
                {
                  "text": "几个",
                  "index": 10,
                  "pinyin": "jǐ gè",
                  "partOfSpeech": "numeral",
                  "translation": "several"
                },
                {
                  "text": "地点",
                  "index": 11,
                  "pinyin": "dìdiǎn",
                  "partOfSpeech": "noun",
                  "translation": "locations"
                },
                {
                  "text": "，",
                  "index": 12,
                  "pinyin": "",
                  "partOfSpeech": "punctuation",
                  "translation": ","
                },
                {
                  "text": "包括",
                  "index": 13,
                  "pinyin": "bāokuò",
                  "partOfSpeech": "verb",
                  "translation": "including"
                },
                {
                  "text": "巴勒斯坦",
                  "index": 14,
                  "pinyin": "Bālèsītǎn",
                  "partOfSpeech": "proper noun",
                  "translation": "Palestine"
                },
                {
                  "text": "广场",
                  "index": 15,
                  "pinyin": "guǎngchǎng",
                  "partOfSpeech": "noun",
                  "translation": "Square"
                },
                {
                  "text": "，",
                  "index": 16,
                  "pinyin": "",
                  "partOfSpeech": "punctuation",
                  "translation": ","
                },
                {
                  "text": "他们",
                  "index": 17,
                  "pinyin": "tāmen",
                  "partOfSpeech": "pronoun",
                  "translation": "they"
                },
                {
                  "text": "挥舞",
                  "index": 18,
                  "pinyin": "huīwǔ",
                  "partOfSpeech": "verb",
                  "translation": "wave"
                },
                {
                  "text": "着",
                  "index": 19,
                  "pinyin": "zhe",
                  "partOfSpeech": "particle",
                  "translation": "-ing"
                },
                {
                  "text": "真主党",
                  "index": 20,
                  "pinyin": "Zhēnzhǔdǎng",
                  "partOfSpeech": "proper noun",
                  "translation": "Hezbollah"
                },
                {
                  "text": "的",
                  "index": 21,
                  "pinyin": "de",
                  "partOfSpeech": "particle",
                  "translation": "of"
                },
                {
                  "text": "旗帜",
                  "index": 22,
                  "pinyin": "qízhì",
                  "partOfSpeech": "noun",
                  "translation": "flags"
                },
                {
                  "text": "，",
                  "index": 23,
                  "pinyin": "",
                  "partOfSpeech": "punctuation",
                  "translation": ","
                },
                {
                  "text": "随着",
                  "index": 24,
                  "pinyin": "suízhe",
                  "partOfSpeech": "preposition",
                  "translation": "as"
                },
                {
                  "text": "扩音器",
                  "index": 25,
                  "pinyin": "kuòyīnqì",
                  "partOfSpeech": "noun",
                  "translation": "loudspeakers"
                },
                {
                  "text": "播放",
                  "index": 26,
                  "pinyin": "bòfàng",
                  "partOfSpeech": "verb",
                  "translation": "play"
                },
                {
                  "text": "出",
                  "index": 27,
                  "pinyin": "chū",
                  "partOfSpeech": "verb",
                  "translation": "out"
                },
                {
                  "text": "宗教",
                  "index": 28,
                  "pinyin": "zōngjiào",
                  "partOfSpeech": "noun",
                  "translation": "religious"
                },
                {
                  "text": "歌曲",
                  "index": 29,
                  "pinyin": "gēqǔ",
                  "partOfSpeech": "noun",
                  "translation": "songs"
                },
                {
                  "text": "，",
                  "index": 30,
                  "pinyin": "",
                  "partOfSpeech": "punctuation",
                  "translation": ","
                },
                {
                  "text": "捶打",
                  "index": 31,
                  "pinyin": "chuídǎ",
                  "partOfSpeech": "verb",
                  "translation": "beat"
                },
                {
                  "text": "着",
                  "index": 32,
                  "pinyin": "zhe",
                  "partOfSpeech": "particle",
                  "translation": "-ing"
                },
                {
                  "text": "胸膛",
                  "index": 33,
                  "pinyin": "xiōngtáng",
                  "partOfSpeech": "noun",
                  "translation": "chests"
                },
                {
                  "text": "。",
                  "index": 34,
                  "pinyin": "",
                  "partOfSpeech": "punctuation",
                  "translation": "."
                }
              ]
            }
          ]
        },
        {
          "id": "paragraph2",
          "text": "伊拉克总理穆罕默德·希亚·苏丹尼宣布伊拉克全国哀悼三天。",
          "translation": "Iraqi Prime Minister Mohammed Shia al-Sudani declared three days of national mourning in Iraq.",
          "sentences": [
            {
              "id": "sentence2",
              "text": "伊拉克总理穆罕默德·希亚·苏丹尼宣布伊拉克全国哀悼三天。",
              "translation": "Iraqi Prime Minister Mohammed Shia al-Sudani declared three days of national mourning in Iraq.",
              "words": [
                {
                  "text": "伊拉克",
                  "index": 0,
                  "pinyin": "Yīlākè",
                  "partOfSpeech": "proper noun",
                  "translation": "Iraq"
                },
                {
                  "text": "总理",
                  "index": 1,
                  "pinyin": "zǒnglǐ",
                  "partOfSpeech": "noun",
                  "translation": "Prime Minister"
                },
                {
                  "text": "穆罕默德·希亚·苏丹尼",
                  "index": 2,
                  "pinyin": "Mùhǎnmòdé·Xīyà·Sūdānní",
                  "partOfSpeech": "proper noun",
                  "translation": "Mohammed Shia al-Sudani"
                },
                {
                  "text": "宣布",
                  "index": 3,
                  "pinyin": "xuānbù",
                  "partOfSpeech": "verb",
                  "translation": "declared"
                },
                {
                  "text": "伊拉克",
                  "index": 4,
                  "pinyin": "Yīlākè",
                  "partOfSpeech": "proper noun",
                  "translation": "Iraq"
                },
                {
                  "text": "全国",
                  "index": 5,
                  "pinyin": "quánguó",
                  "partOfSpeech": "noun",
                  "translation": "national"
                },
                {
                  "text": "哀悼",
                  "index": 6,
                  "pinyin": "āidào",
                  "partOfSpeech": "verb",
                  "translation": "mourning"
                },
                {
                  "text": "三",
                  "index": 7,
                  "pinyin": "sān",
                  "partOfSpeech": "numeral",
                  "translation": "three"
                },
                {
                  "text": "天",
                  "index": 8,
                  "pinyin": "tiān",
                  "partOfSpeech": "noun",
                  "translation": "days"
                },
                {
                  "text": "。",
                  "index": 9,
                  "pinyin": "",
                  "partOfSpeech": "punctuation",
                  "translation": "."
                }
              ]
            }
          ]
        },
        {
          "id": "paragraph3",
          "text": "哈马斯发声明称，以色列的暗杀'只会让巴勒斯坦和黎巴嫩的抵抗更坚定、更持久'。",
          "translation": "Hamas issued a statement saying Israel's assassination 'will only make the resistance in Palestine and Lebanon more determined and lasting'.",
          "sentences": [
            {
              "id": "sentence3",
              "text": "哈马斯发声明称，以色列的暗杀'只会让巴勒斯坦和黎巴嫩的抵抗更坚定、更持久'。",
              "translation": "Hamas issued a statement saying Israel's assassination 'will only make the resistance in Palestine and Lebanon more determined and lasting'.",
              "words": [
                {
                  "text": "哈马斯",
                  "index": 0,
                  "pinyin": "Hāmǎsī",
                  "partOfSpeech": "proper noun",
                  "translation": "Hamas"
                },
                {
                  "text": "发",
                  "index": 1,
                  "pinyin": "fā",
                  "partOfSpeech": "verb",
                  "translation": "issue"
                },
                {
                  "text": "声明",
                  "index": 2,
                  "pinyin": "shēngmíng",
                  "partOfSpeech": "noun",
                  "translation": "statement"
                },
                {
                  "text": "称",
                  "index": 3,
                  "pinyin": "chēng",
                  "partOfSpeech": "verb",
                  "translation": "say"
                },
                {
                  "text": "，",
                  "index": 4,
                  "pinyin": "",
                  "partOfSpeech": "punctuation",
                  "translation": ","
                },
                {
                  "text": "以色列",
                  "index": 5,
                  "pinyin": "Yǐsèliè",
                  "partOfSpeech": "proper noun",
                  "translation": "Israel"
                },
                {
                  "text": "的",
                  "index": 6,
                  "pinyin": "de",
                  "partOfSpeech": "particle",
                  "translation": "'s"
                },
                {
                  "text": "暗杀",
                  "index": 7,
                  "pinyin": "ànshā",
                  "partOfSpeech": "noun",
                  "translation": "assassination"
                },
                {
                  "text": "\"",
                  "index": 8,
                  "pinyin": "",
                  "partOfSpeech": "punctuation",
                  "translation": "\""
                },
                {
                  "text": "只",
                  "index": 9,
                  "pinyin": "zhǐ",
                  "partOfSpeech": "adverb",
                  "translation": "only"
                },
                {
                  "text": "会",
                  "index": 10,
                  "pinyin": "huì",
                  "partOfSpeech": "auxiliary",
                  "translation": "will"
                },
                {
                  "text": "让",
                  "index": 11,
                  "pinyin": "ràng",
                  "partOfSpeech": "verb",
                  "translation": "make"
                },
                {
                  "text": "巴勒斯坦",
                  "index": 12,
                  "pinyin": "Bālèsītǎn",
                  "partOfSpeech": "proper noun",
                  "translation": "Palestine"
                },
                {
                  "text": "和",
                  "index": 13,
                  "pinyin": "hé",
                  "partOfSpeech": "conjunction",
                  "translation": "and"
                },
                {
                  "text": "黎巴嫩",
                  "index": 14,
                  "pinyin": "Líbānèn",
                  "partOfSpeech": "proper noun",
                  "translation": "Lebanon"
                },
                {
                  "text": "的",
                  "index": 15,
                  "pinyin": "de",
                  "partOfSpeech": "particle",
                  "translation": "'s"
                },
                {
                  "text": "抵抗",
                  "index": 16,
                  "pinyin": "dǐkàng",
                  "partOfSpeech": "noun",
                  "translation": "resistance"
                },
                {
                  "text": "更",
                  "index": 17,
                  "pinyin": "gèng",
                  "partOfSpeech": "adverb",
                  "translation": "more"
                },
                {
                  "text": "坚定",
                  "index": 18,
                  "pinyin": "jiāndìng",
                  "partOfSpeech": "adjective",
                  "translation": "determined"
                },
                {
                  "text": "、",
                  "index": 19,
                  "pinyin": "",
                  "partOfSpeech": "punctuation",
                  "translation": ","
                },
                {
                  "text": "更",
                  "index": 20,
                  "pinyin": "gèng",
                  "partOfSpeech": "adverb",
                  "translation": "more"
                },
                {
                  "text": "持久",
                  "index": 21,
                  "pinyin": "chíjiǔ",
                  "partOfSpeech": "adjective",
                  "translation": "lasting"
                },
                {
                  "text": "\"",
                  "index": 22,
                  "pinyin": "",
                  "partOfSpeech": "punctuation",
                  "translation": "\""
                },
                {
                  "text": "。",
                  "index": 23,
                  "pinyin": "",
                  "partOfSpeech": "punctuation",
                  "translation": "."
                }
              ]
            }
          ]
        }
      ]
    }
  },
  {
    "id": 4,
    "metadata": {
      "id": "unique_text_id_20241004",
      "title": "关于纳斯鲁拉之死的报道",
      "source": "示例新闻",
      "difficulty": 4,
      "tags": ["新闻", "政治", "中东"],
      "createdAt": "2024-10-04T14:30:00Z",
      "isFavorite": false,
      "isCompleted": true,
    },
    "content": {
      "fullText": "上周六，哀悼纳斯鲁拉的伊朗人聚集在德黑兰的几个地点，包括巴勒斯坦广场，他们挥舞着真主党的旗帜，随着扩音器播放出宗教歌曲，捶打着胸膛。\n伊拉克总理穆罕默德·希亚·苏丹尼宣布伊拉克全国哀悼三天。\n哈马斯发声明称，以色列的暗杀'只会让巴勒斯坦和黎巴嫩的抵抗更坚定、更持久'。",
      "fullTranslation": "Last Saturday, Iranians mourning Nasrallah gathered at several locations in Tehran, including Palestine Square, waving Hezbollah flags, beating their chests as loudspeakers played religious songs.\nIraqi Prime Minister Mohammed Shia al-Sudani declared three days of national mourning in Iraq.\nHamas issued a statement saying Israel's assassination 'will only make the resistance in Palestine and Lebanon more determined and lasting'.",
      "paragraphs": [
        {
          "id": "paragraph1",
          "text": "上周六，哀悼纳斯鲁拉的伊朗人聚集在德黑兰的几个地点，包括巴勒斯坦广场，他们挥舞着真主党的旗帜，随着扩音器播放出宗教歌曲，捶打着胸膛。",
          "translation": "Last Saturday, Iranians mourning Nasrallah gathered at several locations in Tehran, including Palestine Square, waving Hezbollah flags, beating their chests as loudspeakers played religious songs.",
          "sentences": [
            {
              "id": "sentence1",
              "text": "上周六，哀悼纳斯鲁拉的伊朗人聚集在德黑兰的几个地点，包括巴勒斯坦广场，他们挥舞着真主党的旗帜，随着扩音器播放出宗教歌曲，捶打着胸膛。",
              "translation": "Last Saturday, Iranians mourning Nasrallah gathered at several locations in Tehran, including Palestine Square, waving Hezbollah flags, beating their chests as loudspeakers played religious songs.",
              "words": [
                {
                  "text": "上周六",
                  "index": 0,
                  "pinyin": "shàng zhōu liù",
                  "partOfSpeech": "noun",
                  "translation": "last Saturday"
                },
                {
                  "text": "，",
                  "index": 1,
                  "pinyin": "",
                  "partOfSpeech": "punctuation",
                  "translation": ","
                },
                {
                  "text": "哀悼",
                  "index": 2,
                  "pinyin": "āidào",
                  "partOfSpeech": "verb",
                  "translation": "mourn"
                },
                {
                  "text": "纳斯鲁拉",
                  "index": 3,
                  "pinyin": "Nàsīlǔlā",
                  "partOfSpeech": "proper noun",
                  "translation": "Nasrallah"
                },
                {
                  "text": "的",
                  "index": 4,
                  "pinyin": "de",
                  "partOfSpeech": "particle",
                  "translation": "of"
                },
                {
                  "text": "伊朗人",
                  "index": 5,
                  "pinyin": "Yīlǎng rén",
                  "partOfSpeech": "noun",
                  "translation": "Iranians"
                },
                {
                  "text": "聚集",
                  "index": 6,
                  "pinyin": "jùjí",
                  "partOfSpeech": "verb",
                  "translation": "gather"
                },
                {
                  "text": "在",
                  "index": 7,
                  "pinyin": "zài",
                  "partOfSpeech": "preposition",
                  "translation": "at"
                },
                {
                  "text": "德黑兰",
                  "index": 8,
                  "pinyin": "Déhēilán",
                  "partOfSpeech": "proper noun",
                  "translation": "Tehran"
                },
                {
                  "text": "的",
                  "index": 9,
                  "pinyin": "de",
                  "partOfSpeech": "particle",
                  "translation": "of"
                },
                {
                  "text": "几个",
                  "index": 10,
                  "pinyin": "jǐ gè",
                  "partOfSpeech": "numeral",
                  "translation": "several"
                },
                {
                  "text": "地点",
                  "index": 11,
                  "pinyin": "dìdiǎn",
                  "partOfSpeech": "noun",
                  "translation": "locations"
                },
                {
                  "text": "，",
                  "index": 12,
                  "pinyin": "",
                  "partOfSpeech": "punctuation",
                  "translation": ","
                },
                {
                  "text": "包括",
                  "index": 13,
                  "pinyin": "bāokuò",
                  "partOfSpeech": "verb",
                  "translation": "including"
                },
                {
                  "text": "巴勒斯坦",
                  "index": 14,
                  "pinyin": "Bālèsītǎn",
                  "partOfSpeech": "proper noun",
                  "translation": "Palestine"
                },
                {
                  "text": "广场",
                  "index": 15,
                  "pinyin": "guǎngchǎng",
                  "partOfSpeech": "noun",
                  "translation": "Square"
                },
                {
                  "text": "，",
                  "index": 16,
                  "pinyin": "",
                  "partOfSpeech": "punctuation",
                  "translation": ","
                },
                {
                  "text": "他们",
                  "index": 17,
                  "pinyin": "tāmen",
                  "partOfSpeech": "pronoun",
                  "translation": "they"
                },
                {
                  "text": "挥舞",
                  "index": 18,
                  "pinyin": "huīwǔ",
                  "partOfSpeech": "verb",
                  "translation": "wave"
                },
                {
                  "text": "着",
                  "index": 19,
                  "pinyin": "zhe",
                  "partOfSpeech": "particle",
                  "translation": "-ing"
                },
                {
                  "text": "真主党",
                  "index": 20,
                  "pinyin": "Zhēnzhǔdǎng",
                  "partOfSpeech": "proper noun",
                  "translation": "Hezbollah"
                },
                {
                  "text": "的",
                  "index": 21,
                  "pinyin": "de",
                  "partOfSpeech": "particle",
                  "translation": "of"
                },
                {
                  "text": "旗帜",
                  "index": 22,
                  "pinyin": "qízhì",
                  "partOfSpeech": "noun",
                  "translation": "flags"
                },
                {
                  "text": "，",
                  "index": 23,
                  "pinyin": "",
                  "partOfSpeech": "punctuation",
                  "translation": ","
                },
                {
                  "text": "随着",
                  "index": 24,
                  "pinyin": "suízhe",
                  "partOfSpeech": "preposition",
                  "translation": "as"
                },
                {
                  "text": "扩音器",
                  "index": 25,
                  "pinyin": "kuòyīnqì",
                  "partOfSpeech": "noun",
                  "translation": "loudspeakers"
                },
                {
                  "text": "播放",
                  "index": 26,
                  "pinyin": "bòfàng",
                  "partOfSpeech": "verb",
                  "translation": "play"
                },
                {
                  "text": "出",
                  "index": 27,
                  "pinyin": "chū",
                  "partOfSpeech": "verb",
                  "translation": "out"
                },
                {
                  "text": "宗教",
                  "index": 28,
                  "pinyin": "zōngjiào",
                  "partOfSpeech": "noun",
                  "translation": "religious"
                },
                {
                  "text": "歌曲",
                  "index": 29,
                  "pinyin": "gēqǔ",
                  "partOfSpeech": "noun",
                  "translation": "songs"
                },
                {
                  "text": "，",
                  "index": 30,
                  "pinyin": "",
                  "partOfSpeech": "punctuation",
                  "translation": ","
                },
                {
                  "text": "捶打",
                  "index": 31,
                  "pinyin": "chuídǎ",
                  "partOfSpeech": "verb",
                  "translation": "beat"
                },
                {
                  "text": "着",
                  "index": 32,
                  "pinyin": "zhe",
                  "partOfSpeech": "particle",
                  "translation": "-ing"
                },
                {
                  "text": "胸膛",
                  "index": 33,
                  "pinyin": "xiōngtáng",
                  "partOfSpeech": "noun",
                  "translation": "chests"
                },
                {
                  "text": "。",
                  "index": 34,
                  "pinyin": "",
                  "partOfSpeech": "punctuation",
                  "translation": "."
                }
              ]
            }
          ]
        },
        {
          "id": "paragraph2",
          "text": "伊拉克总理穆罕默德·希亚·苏丹尼宣布伊拉克全国哀悼三天。",
          "translation": "Iraqi Prime Minister Mohammed Shia al-Sudani declared three days of national mourning in Iraq.",
          "sentences": [
            {
              "id": "sentence2",
              "text": "伊拉克总理穆罕默德·希亚·苏丹尼宣布伊拉克全国哀悼三天。",
              "translation": "Iraqi Prime Minister Mohammed Shia al-Sudani declared three days of national mourning in Iraq.",
              "words": [
                {
                  "text": "伊拉克",
                  "index": 0,
                  "pinyin": "Yīlākè",
                  "partOfSpeech": "proper noun",
                  "translation": "Iraq"
                },
                {
                  "text": "总理",
                  "index": 1,
                  "pinyin": "zǒnglǐ",
                  "partOfSpeech": "noun",
                  "translation": "Prime Minister"
                },
                {
                  "text": "穆罕默德·希亚·苏丹尼",
                  "index": 2,
                  "pinyin": "Mùhǎnmòdé·Xīyà·Sūdānní",
                  "partOfSpeech": "proper noun",
                  "translation": "Mohammed Shia al-Sudani"
                },
                {
                  "text": "宣布",
                  "index": 3,
                  "pinyin": "xuānbù",
                  "partOfSpeech": "verb",
                  "translation": "declared"
                },
                {
                  "text": "伊拉克",
                  "index": 4,
                  "pinyin": "Yīlākè",
                  "partOfSpeech": "proper noun",
                  "translation": "Iraq"
                },
                {
                  "text": "全国",
                  "index": 5,
                  "pinyin": "quánguó",
                  "partOfSpeech": "noun",
                  "translation": "national"
                },
                {
                  "text": "哀悼",
                  "index": 6,
                  "pinyin": "āidào",
                  "partOfSpeech": "verb",
                  "translation": "mourning"
                },
                {
                  "text": "三",
                  "index": 7,
                  "pinyin": "sān",
                  "partOfSpeech": "numeral",
                  "translation": "three"
                },
                {
                  "text": "天",
                  "index": 8,
                  "pinyin": "tiān",
                  "partOfSpeech": "noun",
                  "translation": "days"
                },
                {
                  "text": "。",
                  "index": 9,
                  "pinyin": "",
                  "partOfSpeech": "punctuation",
                  "translation": "."
                }
              ]
            }
          ]
        },
        {
          "id": "paragraph3",
          "text": "哈马斯发声明称，以色列的暗杀'只会让巴勒斯坦和黎巴嫩的抵抗更坚定、更持久'。",
          "translation": "Hamas issued a statement saying Israel's assassination 'will only make the resistance in Palestine and Lebanon more determined and lasting'.",
          "sentences": [
            {
              "id": "sentence3",
              "text": "哈马斯发声明称，以色列的暗杀'只会让巴勒斯坦和黎巴嫩的抵抗更坚定、更持久'。",
              "translation": "Hamas issued a statement saying Israel's assassination 'will only make the resistance in Palestine and Lebanon more determined and lasting'.",
              "words": [
                {
                  "text": "哈马斯",
                  "index": 0,
                  "pinyin": "Hāmǎsī",
                  "partOfSpeech": "proper noun",
                  "translation": "Hamas"
                },
                {
                  "text": "发",
                  "index": 1,
                  "pinyin": "fā",
                  "partOfSpeech": "verb",
                  "translation": "issue"
                },
                {
                  "text": "声明",
                  "index": 2,
                  "pinyin": "shēngmíng",
                  "partOfSpeech": "noun",
                  "translation": "statement"
                },
                {
                  "text": "称",
                  "index": 3,
                  "pinyin": "chēng",
                  "partOfSpeech": "verb",
                  "translation": "say"
                },
                {
                  "text": "，",
                  "index": 4,
                  "pinyin": "",
                  "partOfSpeech": "punctuation",
                  "translation": ","
                },
                {
                  "text": "以色列",
                  "index": 5,
                  "pinyin": "Yǐsèliè",
                  "partOfSpeech": "proper noun",
                  "translation": "Israel"
                },
                {
                  "text": "的",
                  "index": 6,
                  "pinyin": "de",
                  "partOfSpeech": "particle",
                  "translation": "'s"
                },
                {
                  "text": "暗杀",
                  "index": 7,
                  "pinyin": "ànshā",
                  "partOfSpeech": "noun",
                  "translation": "assassination"
                },
                {
                  "text": "\"",
                  "index": 8,
                  "pinyin": "",
                  "partOfSpeech": "punctuation",
                  "translation": "\""
                },
                {
                  "text": "只",
                  "index": 9,
                  "pinyin": "zhǐ",
                  "partOfSpeech": "adverb",
                  "translation": "only"
                },
                {
                  "text": "会",
                  "index": 10,
                  "pinyin": "huì",
                  "partOfSpeech": "auxiliary",
                  "translation": "will"
                },
                {
                  "text": "让",
                  "index": 11,
                  "pinyin": "ràng",
                  "partOfSpeech": "verb",
                  "translation": "make"
                },
                {
                  "text": "巴勒斯坦",
                  "index": 12,
                  "pinyin": "Bālèsītǎn",
                  "partOfSpeech": "proper noun",
                  "translation": "Palestine"
                },
                {
                  "text": "和",
                  "index": 13,
                  "pinyin": "hé",
                  "partOfSpeech": "conjunction",
                  "translation": "and"
                },
                {
                  "text": "黎巴嫩",
                  "index": 14,
                  "pinyin": "Líbānèn",
                  "partOfSpeech": "proper noun",
                  "translation": "Lebanon"
                },
                {
                  "text": "的",
                  "index": 15,
                  "pinyin": "de",
                  "partOfSpeech": "particle",
                  "translation": "'s"
                },
                {
                  "text": "抵抗",
                  "index": 16,
                  "pinyin": "dǐkàng",
                  "partOfSpeech": "noun",
                  "translation": "resistance"
                },
                {
                  "text": "更",
                  "index": 17,
                  "pinyin": "gèng",
                  "partOfSpeech": "adverb",
                  "translation": "more"
                },
                {
                  "text": "坚定",
                  "index": 18,
                  "pinyin": "jiāndìng",
                  "partOfSpeech": "adjective",
                  "translation": "determined"
                },
                {
                  "text": "、",
                  "index": 19,
                  "pinyin": "",
                  "partOfSpeech": "punctuation",
                  "translation": ","
                },
                {
                  "text": "更",
                  "index": 20,
                  "pinyin": "gèng",
                  "partOfSpeech": "adverb",
                  "translation": "more"
                },
                {
                  "text": "持久",
                  "index": 21,
                  "pinyin": "chíjiǔ",
                  "partOfSpeech": "adjective",
                  "translation": "lasting"
                },
                {
                  "text": "\"",
                  "index": 22,
                  "pinyin": "",
                  "partOfSpeech": "punctuation",
                  "translation": "\""
                },
                {
                  "text": "。",
                  "index": 23,
                  "pinyin": "",
                  "partOfSpeech": "punctuation",
                  "translation": "."
                }
              ]
            }
          ]
        }
      ]
    }
  },
  {
    "id": 5,
    "metadata": {
      "id": "unique_text_id_20241004",
      "title": "关于纳斯鲁拉之死的报道",
      "source": "示例新闻",
      "difficulty": 4,
      "tags": ["新闻", "政治", "中东"],
      "createdAt": "2024-10-04T14:30:00Z",
      "isFavorite": false,
      "isCompleted": true,
    },
    "content": {
      "fullText": "上周六，哀悼纳斯鲁拉的伊朗人聚集在德黑兰的几个地点，包括巴勒斯坦广场，他们挥舞着真主党的旗帜，随着扩音器播放出宗教歌曲，捶打着胸膛。\n伊拉克总理穆罕默德·希亚·苏丹尼宣布伊拉克全国哀悼三天。\n哈马斯发声明称，以色列的暗杀'只会让巴勒斯坦和黎巴嫩的抵抗更坚定、更持久'。",
      "fullTranslation": "Last Saturday, Iranians mourning Nasrallah gathered at several locations in Tehran, including Palestine Square, waving Hezbollah flags, beating their chests as loudspeakers played religious songs.\nIraqi Prime Minister Mohammed Shia al-Sudani declared three days of national mourning in Iraq.\nHamas issued a statement saying Israel's assassination 'will only make the resistance in Palestine and Lebanon more determined and lasting'.",
      "paragraphs": [
        {
          "id": "paragraph1",
          "text": "上周六，哀悼纳斯鲁拉的伊朗人聚集在德黑兰的几个地点，包括巴勒斯坦广场，他们挥舞着真主党的旗帜，随着扩音器播放出宗教歌曲，捶打着胸膛。",
          "translation": "Last Saturday, Iranians mourning Nasrallah gathered at several locations in Tehran, including Palestine Square, waving Hezbollah flags, beating their chests as loudspeakers played religious songs.",
          "sentences": [
            {
              "id": "sentence1",
              "text": "上周六，哀悼纳斯鲁拉的伊朗人聚集在德黑兰的几个地点，包括巴勒斯坦广场，他们挥舞着真主党的旗帜，随着扩音器播放出宗教歌曲，捶打着胸膛。",
              "translation": "Last Saturday, Iranians mourning Nasrallah gathered at several locations in Tehran, including Palestine Square, waving Hezbollah flags, beating their chests as loudspeakers played religious songs.",
              "words": [
                {
                  "text": "上周六",
                  "index": 0,
                  "pinyin": "shàng zhōu liù",
                  "partOfSpeech": "noun",
                  "translation": "last Saturday"
                },
                {
                  "text": "，",
                  "index": 1,
                  "pinyin": "",
                  "partOfSpeech": "punctuation",
                  "translation": ","
                },
                {
                  "text": "哀悼",
                  "index": 2,
                  "pinyin": "āidào",
                  "partOfSpeech": "verb",
                  "translation": "mourn"
                },
                {
                  "text": "纳斯鲁拉",
                  "index": 3,
                  "pinyin": "Nàsīlǔlā",
                  "partOfSpeech": "proper noun",
                  "translation": "Nasrallah"
                },
                {
                  "text": "的",
                  "index": 4,
                  "pinyin": "de",
                  "partOfSpeech": "particle",
                  "translation": "of"
                },
                {
                  "text": "伊朗人",
                  "index": 5,
                  "pinyin": "Yīlǎng rén",
                  "partOfSpeech": "noun",
                  "translation": "Iranians"
                },
                {
                  "text": "聚集",
                  "index": 6,
                  "pinyin": "jùjí",
                  "partOfSpeech": "verb",
                  "translation": "gather"
                },
                {
                  "text": "在",
                  "index": 7,
                  "pinyin": "zài",
                  "partOfSpeech": "preposition",
                  "translation": "at"
                },
                {
                  "text": "德黑兰",
                  "index": 8,
                  "pinyin": "Déhēilán",
                  "partOfSpeech": "proper noun",
                  "translation": "Tehran"
                },
                {
                  "text": "的",
                  "index": 9,
                  "pinyin": "de",
                  "partOfSpeech": "particle",
                  "translation": "of"
                },
                {
                  "text": "几个",
                  "index": 10,
                  "pinyin": "jǐ gè",
                  "partOfSpeech": "numeral",
                  "translation": "several"
                },
                {
                  "text": "地点",
                  "index": 11,
                  "pinyin": "dìdiǎn",
                  "partOfSpeech": "noun",
                  "translation": "locations"
                },
                {
                  "text": "，",
                  "index": 12,
                  "pinyin": "",
                  "partOfSpeech": "punctuation",
                  "translation": ","
                },
                {
                  "text": "包括",
                  "index": 13,
                  "pinyin": "bāokuò",
                  "partOfSpeech": "verb",
                  "translation": "including"
                },
                {
                  "text": "巴勒斯坦",
                  "index": 14,
                  "pinyin": "Bālèsītǎn",
                  "partOfSpeech": "proper noun",
                  "translation": "Palestine"
                },
                {
                  "text": "广场",
                  "index": 15,
                  "pinyin": "guǎngchǎng",
                  "partOfSpeech": "noun",
                  "translation": "Square"
                },
                {
                  "text": "，",
                  "index": 16,
                  "pinyin": "",
                  "partOfSpeech": "punctuation",
                  "translation": ","
                },
                {
                  "text": "他们",
                  "index": 17,
                  "pinyin": "tāmen",
                  "partOfSpeech": "pronoun",
                  "translation": "they"
                },
                {
                  "text": "挥舞",
                  "index": 18,
                  "pinyin": "huīwǔ",
                  "partOfSpeech": "verb",
                  "translation": "wave"
                },
                {
                  "text": "着",
                  "index": 19,
                  "pinyin": "zhe",
                  "partOfSpeech": "particle",
                  "translation": "-ing"
                },
                {
                  "text": "真主党",
                  "index": 20,
                  "pinyin": "Zhēnzhǔdǎng",
                  "partOfSpeech": "proper noun",
                  "translation": "Hezbollah"
                },
                {
                  "text": "的",
                  "index": 21,
                  "pinyin": "de",
                  "partOfSpeech": "particle",
                  "translation": "of"
                },
                {
                  "text": "旗帜",
                  "index": 22,
                  "pinyin": "qízhì",
                  "partOfSpeech": "noun",
                  "translation": "flags"
                },
                {
                  "text": "，",
                  "index": 23,
                  "pinyin": "",
                  "partOfSpeech": "punctuation",
                  "translation": ","
                },
                {
                  "text": "随着",
                  "index": 24,
                  "pinyin": "suízhe",
                  "partOfSpeech": "preposition",
                  "translation": "as"
                },
                {
                  "text": "扩音器",
                  "index": 25,
                  "pinyin": "kuòyīnqì",
                  "partOfSpeech": "noun",
                  "translation": "loudspeakers"
                },
                {
                  "text": "播放",
                  "index": 26,
                  "pinyin": "bòfàng",
                  "partOfSpeech": "verb",
                  "translation": "play"
                },
                {
                  "text": "出",
                  "index": 27,
                  "pinyin": "chū",
                  "partOfSpeech": "verb",
                  "translation": "out"
                },
                {
                  "text": "宗教",
                  "index": 28,
                  "pinyin": "zōngjiào",
                  "partOfSpeech": "noun",
                  "translation": "religious"
                },
                {
                  "text": "歌曲",
                  "index": 29,
                  "pinyin": "gēqǔ",
                  "partOfSpeech": "noun",
                  "translation": "songs"
                },
                {
                  "text": "，",
                  "index": 30,
                  "pinyin": "",
                  "partOfSpeech": "punctuation",
                  "translation": ","
                },
                {
                  "text": "捶打",
                  "index": 31,
                  "pinyin": "chuídǎ",
                  "partOfSpeech": "verb",
                  "translation": "beat"
                },
                {
                  "text": "着",
                  "index": 32,
                  "pinyin": "zhe",
                  "partOfSpeech": "particle",
                  "translation": "-ing"
                },
                {
                  "text": "胸膛",
                  "index": 33,
                  "pinyin": "xiōngtáng",
                  "partOfSpeech": "noun",
                  "translation": "chests"
                },
                {
                  "text": "。",
                  "index": 34,
                  "pinyin": "",
                  "partOfSpeech": "punctuation",
                  "translation": "."
                }
              ]
            }
          ]
        },
        {
          "id": "paragraph2",
          "text": "伊拉克总理穆罕默德·希亚·苏丹尼宣布伊拉克全国哀悼三天。",
          "translation": "Iraqi Prime Minister Mohammed Shia al-Sudani declared three days of national mourning in Iraq.",
          "sentences": [
            {
              "id": "sentence2",
              "text": "伊拉克总理穆罕默德·希亚·苏丹尼宣布伊拉克全国哀悼三天。",
              "translation": "Iraqi Prime Minister Mohammed Shia al-Sudani declared three days of national mourning in Iraq.",
              "words": [
                {
                  "text": "伊拉克",
                  "index": 0,
                  "pinyin": "Yīlākè",
                  "partOfSpeech": "proper noun",
                  "translation": "Iraq"
                },
                {
                  "text": "总理",
                  "index": 1,
                  "pinyin": "zǒnglǐ",
                  "partOfSpeech": "noun",
                  "translation": "Prime Minister"
                },
                {
                  "text": "穆罕默德·希亚·苏丹尼",
                  "index": 2,
                  "pinyin": "Mùhǎnmòdé·Xīyà·Sūdānní",
                  "partOfSpeech": "proper noun",
                  "translation": "Mohammed Shia al-Sudani"
                },
                {
                  "text": "宣布",
                  "index": 3,
                  "pinyin": "xuānbù",
                  "partOfSpeech": "verb",
                  "translation": "declared"
                },
                {
                  "text": "伊拉克",
                  "index": 4,
                  "pinyin": "Yīlākè",
                  "partOfSpeech": "proper noun",
                  "translation": "Iraq"
                },
                {
                  "text": "全国",
                  "index": 5,
                  "pinyin": "quánguó",
                  "partOfSpeech": "noun",
                  "translation": "national"
                },
                {
                  "text": "哀悼",
                  "index": 6,
                  "pinyin": "āidào",
                  "partOfSpeech": "verb",
                  "translation": "mourning"
                },
                {
                  "text": "三",
                  "index": 7,
                  "pinyin": "sān",
                  "partOfSpeech": "numeral",
                  "translation": "three"
                },
                {
                  "text": "天",
                  "index": 8,
                  "pinyin": "tiān",
                  "partOfSpeech": "noun",
                  "translation": "days"
                },
                {
                  "text": "。",
                  "index": 9,
                  "pinyin": "",
                  "partOfSpeech": "punctuation",
                  "translation": "."
                }
              ]
            }
          ]
        },
        {
          "id": "paragraph3",
          "text": "哈马斯发声明称，以色列的暗杀'只会让巴勒斯坦和黎巴嫩的抵抗更坚定、更持久'。",
          "translation": "Hamas issued a statement saying Israel's assassination 'will only make the resistance in Palestine and Lebanon more determined and lasting'.",
          "sentences": [
            {
              "id": "sentence3",
              "text": "哈马斯发声明称，以色列的暗杀'只会让巴勒斯坦和黎巴嫩的抵抗更坚定、更持久'。",
              "translation": "Hamas issued a statement saying Israel's assassination 'will only make the resistance in Palestine and Lebanon more determined and lasting'.",
              "words": [
                {
                  "text": "哈马斯",
                  "index": 0,
                  "pinyin": "Hāmǎsī",
                  "partOfSpeech": "proper noun",
                  "translation": "Hamas"
                },
                {
                  "text": "发",
                  "index": 1,
                  "pinyin": "fā",
                  "partOfSpeech": "verb",
                  "translation": "issue"
                },
                {
                  "text": "声明",
                  "index": 2,
                  "pinyin": "shēngmíng",
                  "partOfSpeech": "noun",
                  "translation": "statement"
                },
                {
                  "text": "称",
                  "index": 3,
                  "pinyin": "chēng",
                  "partOfSpeech": "verb",
                  "translation": "say"
                },
                {
                  "text": "，",
                  "index": 4,
                  "pinyin": "",
                  "partOfSpeech": "punctuation",
                  "translation": ","
                },
                {
                  "text": "以色列",
                  "index": 5,
                  "pinyin": "Yǐsèliè",
                  "partOfSpeech": "proper noun",
                  "translation": "Israel"
                },
                {
                  "text": "的",
                  "index": 6,
                  "pinyin": "de",
                  "partOfSpeech": "particle",
                  "translation": "'s"
                },
                {
                  "text": "暗杀",
                  "index": 7,
                  "pinyin": "ànshā",
                  "partOfSpeech": "noun",
                  "translation": "assassination"
                },
                {
                  "text": "\"",
                  "index": 8,
                  "pinyin": "",
                  "partOfSpeech": "punctuation",
                  "translation": "\""
                },
                {
                  "text": "只",
                  "index": 9,
                  "pinyin": "zhǐ",
                  "partOfSpeech": "adverb",
                  "translation": "only"
                },
                {
                  "text": "会",
                  "index": 10,
                  "pinyin": "huì",
                  "partOfSpeech": "auxiliary",
                  "translation": "will"
                },
                {
                  "text": "让",
                  "index": 11,
                  "pinyin": "ràng",
                  "partOfSpeech": "verb",
                  "translation": "make"
                },
                {
                  "text": "巴勒斯坦",
                  "index": 12,
                  "pinyin": "Bālèsītǎn",
                  "partOfSpeech": "proper noun",
                  "translation": "Palestine"
                },
                {
                  "text": "和",
                  "index": 13,
                  "pinyin": "hé",
                  "partOfSpeech": "conjunction",
                  "translation": "and"
                },
                {
                  "text": "黎巴嫩",
                  "index": 14,
                  "pinyin": "Líbānèn",
                  "partOfSpeech": "proper noun",
                  "translation": "Lebanon"
                },
                {
                  "text": "的",
                  "index": 15,
                  "pinyin": "de",
                  "partOfSpeech": "particle",
                  "translation": "'s"
                },
                {
                  "text": "抵抗",
                  "index": 16,
                  "pinyin": "dǐkàng",
                  "partOfSpeech": "noun",
                  "translation": "resistance"
                },
                {
                  "text": "更",
                  "index": 17,
                  "pinyin": "gèng",
                  "partOfSpeech": "adverb",
                  "translation": "more"
                },
                {
                  "text": "坚定",
                  "index": 18,
                  "pinyin": "jiāndìng",
                  "partOfSpeech": "adjective",
                  "translation": "determined"
                },
                {
                  "text": "、",
                  "index": 19,
                  "pinyin": "",
                  "partOfSpeech": "punctuation",
                  "translation": ","
                },
                {
                  "text": "更",
                  "index": 20,
                  "pinyin": "gèng",
                  "partOfSpeech": "adverb",
                  "translation": "more"
                },
                {
                  "text": "持久",
                  "index": 21,
                  "pinyin": "chíjiǔ",
                  "partOfSpeech": "adjective",
                  "translation": "lasting"
                },
                {
                  "text": "\"",
                  "index": 22,
                  "pinyin": "",
                  "partOfSpeech": "punctuation",
                  "translation": "\""
                },
                {
                  "text": "。",
                  "index": 23,
                  "pinyin": "",
                  "partOfSpeech": "punctuation",
                  "translation": "."
                }
              ]
            }
          ]
        }
      ]
    }
  },
]

export const wordData: WordDictionaryData = {
  word: '假文',
  pronunciation: 'jiǎwén',
  definitions: [
    '1. to heat',
    '2. to add warmth',
    '3. to raise temperature',
    '4. fig. to stimulate',
  ],
}

export const exercisesData: ExerciseInterface[] = [
  {
    id: 1,
    doc_id: 1,
    title: 'Exercise #1',
    description: 'Document description'
  },
  {
    id: 2,
    doc_id: 2,
    title: 'Exercise #2',
    description: 'Document description'
  },
  {
    id: 3,
    doc_id: 1,
    title: 'Exercise #3',
    description: 'Document description'
  },
  {
    id: 4,
    doc_id: 1,
    title: 'Exercise #4',
    description: 'Document description'
  },
  {
    id: 5,
    doc_id: 2,
    title: 'Exercise #5',
    description: 'Document description'
  },
  {
    id: 6,
    doc_id: 1,
    title: 'Exercise #6',
    description: 'Document description'
  },
  {
    id: 7,
    doc_id: 3,
    title: 'Exercise #7',
    description: 'Document description'
  },
]

export const searchHistoryData: WordDefinition[] = [
  {
    id: 3244,
    word: '假文',
    pinyin: 'jiǎwén',
    audio: 'asd',
    definitions: [
      '1. to heat',
      '2. to add warmth',
      '3. to raise temperature',
      '4. fig. to stimulate',
    ],
  },
  {
    id: 2342,
    word: '假文',
    pinyin: 'jiǎwén',
    audio: 'asd',
    definitions: [
      '1. to heat',
      '2. to add warmth',
      '3. to raise temperature',
      '4. fig. to stimulate',
    ],
  },
  {
    id: 2414,
    word: '假文',
    pinyin: 'jiǎwén',
    audio: 'asd',
    definitions: [
      '1. to heat',
      '2. to add warmth',
      '3. to raise temperature',
      '4. fig. to stimulate',
    ],
  },
]

export const searchResultData: WordDefinition[] = [
  {
    id: 3424,
    word: '元',
    pinyin: 'yuán',
    audio: 'asd',
    definitions: [
      '1. (key) element; essential factor.',
      '2. unit;component',
    ],
  },
  {
    id: 4243,
    word: '元',
    pinyin: 'Yuán',
    audio: 'asd',
    definitions: [
      '1. surname Yuan,',
      '2. the Yuan ot Mongol dynsty',
    ],
  },
]

export const wordDetail: WordDefinition = {
  id: 3424,
  word: '元',
  pinyin: 'yuán',
  audio: 'add',
  definitions: [
    {
      dictionary: 'Dictionary name #1',
      meanings: [
        {
          meaning: '1. (key) element; essential factor.',
          examples: ['多元']
        },
        {
          meaning: '2. unit;component',
          examples: ['单元']
        },
      ]
    },
    {
      dictionary: 'Dictionary name #2',
      meanings: [
        {
          meaning: '1. currency unit (esp. Chinese yuan)',
          examples: ['多元','元始', '元恶']
        },
        {
          meaning: '2. (bound form) first; original; primary',
          examples: ['单元']
        },
      ]
    },
  ]
}