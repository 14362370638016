import { useRef, useState } from 'react';
import { Flex, Typography, Popover, Col, Row } from 'antd';
import DictionaryPop from './DictionaryPop';
import AudioPlayer from '../common/AudioPlayer';
import { DocumentDto, DocumentInterface, Word } from '../../data/interfaces/document';

const { Text } = Typography;

const TextTabContent = ({doc, items}: {doc?: DocumentDto, items: string[]}) => {
  const [selectedWord, setSelectedWord] = useState<Word | null>(null);
  const [popoverVisible, setPopoverVisible] = useState(false);
  const [popoverPosition, setPopoverPosition] = useState<{ top: number; left: number }>({ top: 0, left: 0 });
  const timerRef = useRef<number | null>(null);
  const textContainerRef = useRef<HTMLDivElement>(null);

  const handleWordClick = (word: Word, event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    const rect = event.currentTarget.getBoundingClientRect();

    const popoverLeft = window.innerWidth - rect.left < 200 
      ? Math.max(0, rect.left + window.scrollX - 200) 
      : rect.left + window.scrollX;

    setPopoverPosition({
      top: rect.top + window.scrollY + 20,
      left: popoverLeft,
    });

    setSelectedWord(word);

    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = window.setTimeout(() => {
      setPopoverVisible(true);
    }, 500);
  }

  const handleVisibleChange = (open: any) => {
    setPopoverVisible(open)
  }

  return (
    <Flex gap={16} vertical style={{ padding: '0 1rem', minHeight: 0 }}>
      <AudioPlayer url='' />
      {/* <Flex vertical gap={16}>
        {doc?.content?.paragraphs.map((p, p_index) => (
          <Row key={p_index} gutter={16} className='detail-header'>
            {items.includes('chinese') && <Col ref={textContainerRef} className='chinese-section' xs={24} md={ 24 / items.length}>
              {p.sentences.map((s) => s.words.map((w, w_index) => (
                <Text 
                  key={p_index + '_' + w_index}
                  onClick={(e) => handleWordClick(w, e)}
                  tabIndex={0}
                >
                  {w.text}
                </Text>
              )))}
            </Col>}
            {items.includes('pinyin') && <Col xs={24} md={ 24 / items.length}>
              {p.sentences.map((s) => s.words.map((w, w_index) => <Text key={p_index + '_' + w_index}>{w.partOfSpeech === 'punctuation' ? w.text : w.pinyin + ' '}</Text>))}
            </Col>}
            {items.includes('english') && <Col xs={24} md={ 24 / items.length}>
              {p.sentences.map((s, s_index) => <Text key={p_index + '_' + s_index}>{s.translation}</Text>)}
            </Col>}
          </Row>
        ))}
      </Flex> */}
      <Popover
        content={selectedWord && <DictionaryPop word={selectedWord} />}
        open={popoverVisible}
        trigger={["click"]}
        onOpenChange={handleVisibleChange}
        placement='bottom'
        overlayStyle={{
          position: 'absolute',
          top: `${popoverPosition.top}px`,
          left: `${popoverPosition.left}px`
        }}
      />
    </Flex>
  );
};

export default TextTabContent;