import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { Card, Typography } from 'antd';

const { Text } = Typography;

const StyledCard = styled(Card)`
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  cursor: pointer;
  background: transparent;
`

const DetailTag = ({title, href, content, icon}: {title: string, href: string, content?: string, icon: React.ReactNode}) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(href);
  }

  return (
    <StyledCard
      styles={{ body: { gap: '.5rem', display: 'flex', padding: '1rem 1.5rem', flexDirection: 'column' } }}
      onClick={handleClick}
    >
      {icon}
      <Text style={{ color: 'white', fontWeight: 'bold' }}>{title}</Text>
      <Text style={{ color: 'white' }}>{content}</Text>
    </StyledCard>
  );
};

export default DetailTag;