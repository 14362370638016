import React, { useState } from 'react';
import { Flex, Input, Row, Typography } from 'antd';
import { searchHistoryData, searchResultData } from '../../data/mockUp';
import SearchResult from '../../components/dictionary/SearchResult';

const { Search } = Input;
const { Title } = Typography;

const DictionaryPage: React.FC = () => {
  const [keyword, setKeyword] = useState<string>('');

  const onSearch = (value: string) => {
    setKeyword(value);
  }

  return (
    <Row className='w-full'>
      <Flex className='flex bg-white justify-between align-center w-full container'>
        <Title level={3} style={{ marginBottom: '0' }}>Dictionary</Title>
        <Search placeholder='Search' onSearch={onSearch} className='search-bar' style={{ borderRadius: 2 }} />
      </Flex>
      <Flex vertical className='container w-full'>
        <Title level={5} style={{ paddingBottom: '1rem', marginBottom: 0, borderBottom: '1px solid #DDE1E6' }}>{keyword ? 'Search result' : 'Previous search results'}</Title>
        {keyword ? 
          searchResultData.map((s, index) => <SearchResult key={index} result={s} />) :
          searchHistoryData.map((s, index) => <SearchResult key={index} result={s} />)
        }
      </Flex>
    </Row>
  );
};

export default DictionaryPage;