import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Layout as AntLayout, Avatar, Dropdown, Flex, Image, Menu, MenuProps } from 'antd';
import { useMediaQuery } from 'react-responsive';
import { UserOutlined, LogoutOutlined, BookOutlined, MenuOutlined, BankOutlined, TranslationOutlined, CloseOutlined } from '@ant-design/icons';
import Title from 'antd/es/typography/Title';
import { RootState } from "../../store";
import { signOut } from '../../utils/supabase';
import LogoIcon from "../../assets/logo.svg";
import "./layout.css";

const { Header } = AntLayout;

const HeaderComponent = () => {
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state: RootState) => state.auth.isAuthenticated)
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const [selectedTab, setSelectedTab] = useState<string>('library');
  const [collapsed, setCollapsed] = useState<boolean>(true);

  type MenuItem = Required<MenuProps>['items'][number];

  const handleLogout = async () => {
    try {
      await signOut(dispatch);
    } catch (error) {
    }
  }

  const handleMenuClick = (e: any) => {
    setSelectedTab(e.key);
    
  }

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  }

  const items: MenuProps['items'] = [
    {
      key: 1,
      label: <Menu.Item icon={<LogoutOutlined />} onClick={handleLogout}>Logout</Menu.Item>
    }
  ]

  const menuItems: MenuItem[] = [
    {
      label: (<Link to='/documents'>Library</Link>),
      key: 'library',
      icon: <BankOutlined />,
    },
    {
      label: (<Link to='/dictionary'>Dictionary</Link>),
      key: 'dictionary',
      icon: <TranslationOutlined />
    },
    {
      label: (<Link to='/documents'>Flashcards</Link>),
      key: 'flashcards',
      icon: <BookOutlined />
    },
  ]

  return (
    <Header className="flex justify-between align-center" style={{ background: 'black', padding: '.5rem 1.5rem' }}>
      <Flex gap={24} align='center'>
        <Flex gap={16} align='center'>
          {isMobile ? 
            collapsed ? 
              <MenuOutlined style={{ color: 'white', fontSize: '16px' }} onClick={toggleCollapsed} /> : 
              <CloseOutlined style={{ color: 'white', fontSize: '16px '}} onClick={toggleCollapsed} /> :
            <Image src={LogoIcon} alt='logo' width={32} preview={false} />
          }
          <Title level={4} style={{ marginBottom: 0, color: 'white' }}>Bao</Title>
        </Flex>
        {!isMobile && isAuthenticated && (
          <Menu 
            onClick={handleMenuClick} 
            selectedKeys={[selectedTab]} 
            mode='horizontal' 
            items={menuItems} 
            theme='dark'
            style={{ background: 'transparent', width: '500px' }} 
          />
        )}
        {isMobile && !collapsed && (
          <Menu
            mode="inline"
            selectedKeys={[selectedTab]}
            inlineCollapsed={collapsed}
            items={menuItems}
            theme='dark'
            style={{ position: 'absolute', top: '4rem', left: 0, background: 'black', zIndex: 9, width: '14rem', height: '100%' }}
            onClick={handleMenuClick}
          />
        )}
      </Flex>
      {isAuthenticated && (
        <Dropdown menu={{items}} trigger={['click']}>
          <Avatar
            style={{ float: 'right' }}
            icon={<UserOutlined />}
          />
        </Dropdown>
      )}
    </Header>
  );
};

export default HeaderComponent;
