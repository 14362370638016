import { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Card, Button, Tag, Flex, Dropdown, MenuProps, Typography } from 'antd';
import { CheckCircleOutlined, ClockCircleOutlined, MoreOutlined, StarFilled, StarOutlined } from '@ant-design/icons';
import { DocumentDto, DocumentInterface } from '../../data/interfaces/document';

const { Title } = Typography

const moreTagsItems: MenuProps['items'] = [
  {
    key: 1,
    label: (
      <Flex align='center' gap={8}>
        <CheckCircleOutlined style={{ color: '#52C41A'}} />
        Flashcards
      </Flex>
    )
  },
  {
    key: 2,
    label: (
      <Flex align='center' gap={8}>
        <CheckCircleOutlined style={{ color: '#52C41A'}} />
        Exercises
      </Flex>
    )
  },
]

const tagItems = [
  {
    id: 1,
    label: 'Pinyin'
  },
  {
    id: 2,
    label: 'English'
  },
  {
    id: 3,
    label: 'Audio'
  },
]

const StyledCard = styled(Card)`
  border-radius: 8px;
  border: 1px solid #d9d9d9;
  cursor: pointer;
  width: fit-content;

  &:hover {
    background: #F5F5F5;
  }
`

const DocumentCard = ({document, goToDetail, editText, deleteText}: {document?: DocumentDto, goToDetail: (id: string) => void, editText: (doc?: DocumentDto) => void, deleteText: (doc?: DocumentDto) => void}) => {
  const [documentDetail, setDocumentDetail] = useState<DocumentInterface | null>(null);

  const moreItems: MenuProps['items'] = [
    {
      key: 1,
      label: 'Settings'
    },
    {
      key: 2,
      label: 'Edit text',
      onClick: () => editText(document)
    },
    {
      key: 3,
      label: 'Delete',
      onClick: () => deleteText(document)
    }
  ];

  useEffect(() => {
    if (document) {
      setDocumentDetail(JSON.parse(document.json_structure));
    }
  }, [document])
  
  if (!document) {
    return null;
  }
  
  return (
    <StyledCard
      styles={{ body: { gap: '.5rem', display: 'flex', padding: '1rem 1.5rem', flexDirection: 'column' } }}
      onClick={() => goToDetail(document.id)}
    >
      <Flex justify='space-between' align='center'>
        <Title level={3} className='more' style={{ width: '50%', marginBottom: 0 }}>{document?.titleMand}</Title>
        <Flex align='center' gap={16}>
          {!document?.is_favorite ? <StarOutlined style={{ fontSize: 24 }} /> : <StarFilled style={{ color: '#FFCC00', fontSize: 24 }} />}
          <div onClick={(e) => e.stopPropagation()}>
            <Dropdown menu={{items: moreItems}} trigger={['click']}>
              <MoreOutlined rotate={90} style={{ fontSize: 24 }} />
            </Dropdown>
          </div>
        </Flex>
      </Flex>
      <Title level={5} style={{ marginBottom: 0 }}>{documentDetail?.metadata.source}</Title>
      <Flex>
        {tagItems.map((tag) => (
          <Tag key={tag.id} style={{ border: documentDetail?.metadata.isCompleted ? '1px solid #B7EB8F' : '1px solid #91D5FF', background: documentDetail?.metadata.isCompleted ? '#F6FFED' : '#E6F7FF', color: documentDetail?.metadata.isCompleted ? '#52C41A' : '#1890FF', borderRadius: 2 }}>
            <Flex align='center' style={{ gap: 4 }}>
              {documentDetail?.metadata.isCompleted ? <CheckCircleOutlined /> : <ClockCircleOutlined />}
              {tag.label}
            </Flex>
          </Tag>
        ))}
        <div onClick={(e) => e.stopPropagation()}>
          <Dropdown menu={{items: moreTagsItems}} trigger={['click']}>
            <Button size="small">+2</Button>
          </Dropdown>
        </div>
      </Flex>
    </StyledCard>
  );
};

export default DocumentCard;