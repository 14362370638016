import React from 'react';
import { Collapse, CollapseProps, Flex, Row, Typography } from 'antd';
import { LeftOutlined, SoundOutlined } from '@ant-design/icons';
import { useNavigate, useParams } from 'react-router-dom';
import { wordDetail } from '../../../data/mockUp';
import './index.css';

const { Title, Text } = Typography;

const DictionaryDetailPage: React.FC = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const meaning = (meanings: any) => (
    <Flex vertical className='w-full' style={{ borderBottom: '1px solid #DDE1E6', padding: '1rem' }}>
      {meanings.map((m: any) => (
        <Flex vertical>
          <Text>{m.meaning}</Text>
          <Text>
            Examples: 
            {m.examples.map((e: any) => <span style={{ color: '#007AFF'}}>{` ${e} `}</span>)}
          </Text>
        </Flex>
      ))}
    </Flex>
  )

  const items: CollapseProps['items'] = wordDetail.definitions.map((definition, index) => {
    return {
      key: index, 
      label: (<Title level={5} style={{ marginBottom: 0 }}>{definition.dictionary}</Title>), 
      children: meaning(definition.meanings)}
  })

  const handleBack = () => {
    navigate(-1);
  }

  return (
    <Row className='w-full'>
      <Flex className='flex bg-white justify-between align-center w-full container'>
        <Title level={3} style={{ marginBottom: '0' }}>
          <LeftOutlined onClick={handleBack} style={{ color: '#007AFF', fontSize: '1rem', cursor: 'pointer', marginRight: '.5rem' }} /> 
          {`${wordDetail.word} ${wordDetail.pinyin}`} 
          {wordDetail.audio && <SoundOutlined style={{ color: '#007AFF', fontSize: '1rem', cursor: 'pointer', marginLeft: '.5rem' }} />}
        </Title>
      </Flex>
      <Flex className='container w-full'>
        <Collapse ghost items={items} style={{ width: '100%' }} />
      </Flex>
    </Row>
  );
};

export default DictionaryDetailPage;