import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Button, Col, Flex, Row } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import Title from 'antd/es/typography/Title';
import ExerciseCard from '../../../components/exercises/ExerciseCard';
import { exercisesData } from '../../../data/mockUp';
import { ExerciseInterface } from '../../../data/interfaces/exercise';

const ExercisesPage: React.FC = () => {
  const navigate = useNavigate();
  const { documentId } = useParams();
  const [exercises, setExercises] = useState<ExerciseInterface[]>([]);

  useEffect(() => {
    if (documentId) {
      setExercises(exercisesData.filter((e) => e.doc_id.toString() === documentId))
    }
  }, [documentId])

  const handleExerciseClick = (exe: ExerciseInterface) => {
    navigate(`/exercises/${documentId}/${exe.id}`)
  }

  const handleBackClick = () => {
    navigate(`/documents/${documentId}`)
  }

  return (
    <Row className='w-full'>
      <Flex className='flex bg-white justify-between align-center w-full container'>
        <Title level={3} style={{ marginBottom: '0' }}>Complete Exercises</Title>
        <Button icon={<CloseOutlined />} style={{ border: 'none', boxShadow: 'none' }} onClick={handleBackClick} />
      </Flex>

      <Row gutter={[{ xs: 16, md: 24 }, { xs: 16, sm: 24 }]} className='container'>
        {exercises.map((exe) => (
          <Col xs={24} sm={12} xl={8} xxl={6} key={exe.id}>
            <ExerciseCard exercise={exe} key={exe.id} goToDetail={handleExerciseClick} />
          </Col>
        ))}
      </Row>
    </Row>
  );
};

export default ExercisesPage;