import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';
import { Button, Flex, Row, Space, Col, Tabs, TabsProps, Select, Typography, message } from 'antd';
import { ArrowLeftOutlined, BlockOutlined, FileDoneOutlined } from '@ant-design/icons';
import TextTabContent from '../../../components/documents/TextTab';
import DetailTag from '../../../components/documents/DetailTag';
import { DocumentDto } from '../../../data/interfaces/document';
import { getTextById } from '../../../utils/supabase';
import './index.css';

const { Title } = Typography;

const DocumentDetailPage: React.FC = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery({ query: '(max-width: 767px)' });
  const { id } = useParams();
  const [document, setDocument] = useState<DocumentDto | undefined>(undefined);
  const [selectedItems, setSelectedItems] = useState(['chinese']);

  useEffect(() => {
    if (id) {
      const fetchData = async () => {
        try {
          const response = await getTextById(id);
          if (response) {
            setDocument(response[0]);
          }
        } catch (error) {
          message.error((error as Error).message)
        }
      };
  
      fetchData();
    }
  }, [id]);

  const handleBackClick = () => {
    navigate('/documents')
  }

  const goToExercises = () => {
    navigate(`/exercises/${document?.id}`)
  }

  const handleChange = (value: string[]) => {
    if (value.length === 0) {
      message.warning('Your must select at least one option!');
      return;
    }
    setSelectedItems(value);
  };
  
  const options = [
    {
      label: 'Chinese',
      value: 'chinese',
    },
    {
      label: 'Pinyin',
      value: 'pinyin',
    },
    {
      label: 'English',
      value: 'english',
    },
  ];

  const tabItems: TabsProps['items'] = [
    {
      key: '1',
      label: 'Text',
      children: (<TextTabContent doc={document} items={selectedItems} />)
    },
    {
      key: '2',
      label: 'Words',
    },
    {
      key: '3',
      label: 'Phrases',
    },
  ]

  return (
    <Row className='w-full'>
      <Flex className='flex-col w-full container bg-white' gap={8}>
        <Flex align='center' justify='space-between' className='detail-header'>
          <Flex className='align-center' gap={16}>
            <Button icon={<ArrowLeftOutlined />} style={{ border: 'none', boxShadow: 'none' }} onClick={handleBackClick} />
            <Title level={3} style={{ marginBottom: '0' }}>{document?.titleMand}</Title>
          </Flex>
          <Select
            mode="multiple"
            style={{ borderRadius: 2, alignSelf: 'end' }}
            defaultValue={['chinese']}
            value={selectedItems}
            onChange={handleChange}
            options={options}
          />
        </Flex>
        {/* <Title level={5} style={{ marginBottom: 0 }}>{document?.metadata.source}</Title> */}
      </Flex>
      {isMobile && (
        <Row className='w-full' style={{ padding: 16 }} gutter={16}>
          <Col span={12}><DetailTag title='Flashcards' href='' icon={<BlockOutlined style={{ color: 'white', fontSize: 32 }} />} /></Col>
          <Col span={12}><DetailTag title='Exercises' href={`/exercises/${document?.id}`} icon={<FileDoneOutlined style={{ color: 'white', fontSize: 32 }} />} /></Col>
        </Row>
      )}
      <Row className='container detail-header' gutter={24}>
        <Col xs={24} md={18}>
          <Space className='bg-white w-full' style={{ padding: '.5rem 0', borderRadius: 8 }}>
            <Tabs className='w-full' defaultActiveKey="1" items={tabItems} tabBarStyle={{ padding: '0 1rem' }} />
          </Space>
        </Col>
        {!isMobile && (
          <Col xs={24} md={6}>
            <Row style={{ background: '#8F74F0', borderRadius: 8 }}><DetailTag title='Flashcards' href='' content='Use flashcards to enhance your memory through active recall and repetition.' icon={<BlockOutlined style={{ color: 'white', fontSize: 32 }} />} /></Row>
            <Row style={{ background: 'rgb(240, 62, 166)', borderRadius: 8, marginTop: 24 }}><DetailTag title='Exercises' href={`/exercises/${document?.id}`} content='Exersices are designed to help you assess your knowledge and identify areas where you may need improvement.' icon={<FileDoneOutlined style={{ color: 'white', fontSize: 32 }} />} /></Row>
          </Col>
        )}
      </Row>
    </Row>
  );
};

export default DocumentDetailPage;