import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Button, Col, Flex, Input, message, Row, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import DocumentCard from '../../../components/documents/DocumentCard';
import DocumentAddModal from '../../../components/documents/DocumentAddModal';
import DocumentEditModal from '../../../components/documents/DocumentEditModal';
import DocumentDeleteModal from '../../../components/documents/DocumentDeleteModal';
import { DocumentDto } from '../../../data/interfaces/document';
import { RootState } from '../../../store';
import { getUserTexts } from '../../../utils/supabase';
import './index.css';

const { Search } = Input;
const { Title } = Typography;

const DocumentsPage: React.FC = () => {
  const navigate = useNavigate();
  const email = useSelector((state: RootState) => state.auth.user.email);
  const [openAddTextModal, setOpenAddTextModal] = useState<boolean>(false);
  const [openEditTextModal, setOpenEditTextModal] = useState<boolean>(false);
  const [openDeleteTextModal, setOpenDeleteTextModal] = useState<boolean>(false);
  const [selectedDocument, setSelectedDocument] = useState<DocumentDto | null>(null);
  const [documents, setDocuments] = useState<DocumentDto[]>([]);

  useEffect(() => {
    fetchData();
  }, [email])
  
  const fetchData = async () => {
    try {
      const response = await getUserTexts(email);
      if (response) {
        setDocuments(response);
      }
    } catch (error) {
      message.error((error as Error).message)
    }
  };

  const onSearch = (value: string) => {
    console.log(value);
  }

  // const handleFavorite = (id: number) => {
  //   let document = documentsData.find((d) => d.id === id);
  //   if (document) {
  //     document.isFavorite = !document.isFavorite
  //   }
  // }

  const editText = (doc?: DocumentDto) => {
    if (doc) {
      setOpenEditTextModal(true);
      setSelectedDocument(doc);
    }
  }

  const deleteText = (doc?: DocumentDto) => {
    if (doc) {
      setOpenDeleteTextModal(true);
      setSelectedDocument(doc);
    }
  }

  const goToDetail = (id: string) => {
    navigate(`/documents/${id}`);
  }

  return (
    <Row className='w-full'>
      <Flex className='flex bg-white justify-between align-center w-full container'>
        <Title level={3} style={{ marginBottom: '0' }}>Library</Title>
        <Flex gap={24} align='center'>
          <Search placeholder='Search' onSearch={onSearch} className='search-bar' style={{ borderRadius: 2 }} />
          <Button type="primary" icon={<PlusOutlined />} style={{ borderRadius: 0 }} onClick={() => setOpenAddTextModal(true)}>
            New
          </Button>
        </Flex>
      </Flex>

      <Row gutter={[{ xs: 16, md: 24 }, { xs: 16, sm: 24 }]} className='container'>
        {documents.map((doc) => (
          <Col xs={24} sm={12} xl={8} xxl={6} key={doc.id}>
            <DocumentCard document={doc} goToDetail={goToDetail} editText={() => editText(doc)} deleteText={() => deleteText(doc)} />
          </Col>
        ))}
        {/* {documentsData.map((doc) => (
          <Col xs={24} sm={12} xl={8} xxl={6} key={doc.id}>
            <DocumentCard document={doc} goToDetail={goToDetail} editText={() => editText(doc)} deleteText={() => deleteText(doc)} />
          </Col>
        ))} */}
      </Row>

      <DocumentAddModal open={openAddTextModal} setOpen={setOpenAddTextModal} callback={fetchData} />
      <DocumentEditModal open={openEditTextModal} setOpen={setOpenEditTextModal} document={selectedDocument} callback={fetchData} />
      <DocumentDeleteModal open={openDeleteTextModal} setOpen={setOpenDeleteTextModal} document={selectedDocument} callback={fetchData} />
    </Row>
  );
};

export default DocumentsPage;