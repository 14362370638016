import { useState } from 'react';
import { Button, Flex, Modal, Typography, Space, message } from 'antd';
import { DocumentDto } from '../../data/interfaces/document';
import { deleteText } from '../../utils/supabase';

const { Text } = Typography;

const DocumentDeleteModal = ({document, open, setOpen, callback}: {document: DocumentDto | null, open: boolean, setOpen: (value: boolean) => void, callback: () => Promise<void>}) => {
  const [loading, setLoading] = useState<boolean>(false);

  const handleSubmit = async () => {
    try {
      if (document) {
        setLoading(true);
        await deleteText(document?.id);
        message.success('The document deleted!');
        setLoading(false);
        callback();
      }
    } catch (error) {
      message.error((error as Error).message)
      setLoading(false);
    }
    setOpen(false);
  }

  return (
    <Space onClick={(e) => e.stopPropagation()}>
      <Modal
        open={open}
        title={`Delete ${document?.titleMand}?`}
        onCancel={() => setOpen(false)}
        footer={[
          <Button key='cancel' onClick={() => setOpen(false)} style={{ borderRadius: 2 }}>Cancel</Button>,
          <Button key='delete' type='primary' loading={loading} onClick={handleSubmit} style={{ borderRadius: 2 }}>Delete</Button>
        ]}
        centered
      >
        <Flex vertical gap={24} style={{ borderTop: '1px solid #D9D9D9', borderBottom: '1px solid #D9D9D9', padding: '1rem 0' }}>
          <Text>Are you sure you want to delete document? This actions can't be undone</Text>
        </Flex>
      </Modal>
    </Space>
  );
};

export default DocumentDeleteModal;